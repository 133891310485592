<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <h1 class="text-center mb-0">
                    {{ year.year }}
                </h1>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <div class="mt-4 mb-4 justify-content-center justify-content-md-end status-buttons d-flex">
                    <publish-button
                        :model="year"
                        model-type="years"
                        :published="year.published"
                        @update="updateYear"
                    />
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <show-year-dates
                    :year="year"
                    @update="updateYear"
                />
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <show-yearly-fees :year="year" />
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <category-list
                    :year="year"
                    :init-categories="year.categories"
                />
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            initYear: {
                type: Object,
            },
        },
        data() {
            return {
                year: this.initYear
            }
        },
        methods: {
            updateYear(data) {
                console.log('updating year');

                this.year = data;

            }
        },
    }
</script>

<style lang="scss" scoped>

</style>

<template>
    <standard-box class="mb-4">
        <template #title>
            <h6 class="m-0 font-weight-bold">
                <i class="fa fa-scroll mr-2" />Rules
            </h6>
            <div
                v-if="rules && rules.description"
                class="ml-auto actions fs-12"
            >
                <p-check
                    v-model="editMode"
                    class="p-switch p-slim mr-0"
                >
                    Edit
                </p-check>
            </div>
        </template>
        <template #body>
            <div class="row">
                <div class="col-md-12">
                    <div v-if="rules">
                        <div
                            v-if="hasFile"
                            class="list-group list-group-flush"
                        >
                            <div class="list-group-item d-flex align-items-center">
                                <a
                                    :href="rules.public_url"
                                    target="_blank"
                                >
                                    <i class="fa fa-file-pdf mr-2" />PDF
                                </a>
                                <button
                                    class="ml-auto delete-btn d-block btn btn-default text-danger"
                                    @click.prevent="deleteFile"
                                >
                                    <i class="fa fa-trash" />
                                </button>
                            </div>
                        </div>
                        <div
                            v-if="rules.description"
                        >
                            <template v-if="editMode">
                                <WYSIWYG-field
                                    v-model="newContent"
                                    class="m-0"
                                />
                                <div class="p-4 actions d-flex align-items-center justify-content-center">
                                    <button
                                        class="btn btn-default mr-5"
                                        @click.prevent="cancelEdit"
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        class="btn btn-success"
                                        @click.prevent="updateContent"
                                    >
                                        Update
                                    </button>
                                </div>
                            </template>
                            <div
                                v-else
                                class="p-4"
                                v-html="rules.description"
                            />
                        </div>
                    </div>
                    <template>
                        <template v-if="state === 'new'">
                            <div
                                v-if="!(hasFile && hasContent)"
                                class="p-4 d-flex align-items-center justify-content-center new-rule-options"
                            >
                                <file-upload
                                    v-if="!hasFile"
                                    ref="upload"
                                    v-model="file"
                                    :headers="headers"
                                    class="border btn btn-default mr-2"
                                    :data="formData"
                                    post-action="/api/rules"
                                    :multiple="false"
                                    :upload-auto="true"
                                    :drop="true"
                                    :drop-directory="true"
                                    @input-file="inputFile"
                                >
                                    Upload PDF
                                </file-upload>
                                <template v-if="!rules">
                                    or
                                </template>
                                <button
                                    v-if="!hasContent"
                                    class="border btn btn-default ml-2"
                                    @click.prevent="setState('content')"
                                >
                                    Add Content
                                </button>
                            </div>
                        </template>
                        <template v-else>
                            <WYSIWYG-field
                                v-model="content"
                                class="m-0"
                            />
                            <div class="p-4 actions d-flex align-items-center justify-content-center">
                                <button
                                    class="btn btn-default mr-5"
                                    @click.prevent="clear"
                                >
                                    Cancel
                                </button>
                                <button
                                    class="btn btn-primary"
                                    @click.prevent="saveContent"
                                >
                                    Save
                                </button>
                            </div>
                        </template>
                    </template>
                </div>
            </div>
        </template>
    </standard-box>
</template>

<script>
    import FileUpload from 'vue-upload-component'
    export default {
        components: { FileUpload },
        props: {
            initModel: {
                type: Object
            },
            modelType: {
                type: String
            }
        },
        data() {
            return {
                model: this.initModel,
                state: 'new',
                editMode: false,
                file: [],
                content: '',
                newContent: '',
                headers: {
                    'X-Csrf-Token': document.head.querySelector('meta[name="csrf-token"]').content
                },

            }
        },
        computed: {
            hasContent() {
                return this.model.rules && this.model.rules.description
            },
            hasFile() {
                return this.model.rules && this.model.rules.path
            },
            rules() {
                return this.model.rules
            },
            formData() {
                return {
                    program_id: this.model.id
                }
            }
        },
        watch: {
            editMode(newValue, oldValue) {
                if (!newValue) {
                    this.newContent = this.model.rules.description;
                }

            }
        },
        mounted () {
            if (this.hasContent) {
                this.newContent = this.model.rules.description;
            }
        },
        methods: {
            cancelEdit() {
                this.editMode = false
            },
            updateContent() {
                this.loading = true;
                        axios
                            .patch(`/api/rules/${this.model.rules.id}`, {
                                description: this.newContent
                            })

                            .then(r => {
                                this.model.rules.description = this.newContent;
                                this.$toasted.show('Rule updated!', {
                                    type: 'success',
                                    duration: 700
                                });
                                this.editMode = false;
                                this.loading = false;
                            })
                            .catch(e => {
                                this.loading = false;
                                console.log(e);
                            })
            },
            deleteFile() {
                this.$swal({
                    type: 'error',
                    text: 'Are you sure?',
                    showConfirmButton: true,
                    showCancelButton: true,
                    reverseButtons: true
                }).then(r => {
                    if (r.value) {
                        axios
                            .delete(`/api/rules/${this.model.rules.id}/file`)
                            .then(r => {
                                this.model.rules.path = null;
                                this.$toasted.show('File deleted!', {
                                    type: 'success',
                                    duration: 1000
                                })
                            })
                            .catch(e => {
                                console.log(e);
                            })
                    }
                })

            },
            saveContent(data) {
                this.loading = true;
                axios
                    .post('/api/rules', {
                        program_id: this.model.id,
                        description: this.content
                    })
                    .then(r => {
                        this.model.rules = r.data.rules;
                        this.state = 'new';
                        this.$toasted.show('Rules saved!', {
                            type: 'success',
                            duration: 1000
                        })
                        this.loading = false;
                    })
                    .catch(e => {
                        console.log(e);
                        this.loading = false;
                    })

            },
            inputFile: function (newFile, oldFile) {
                // Add file
                if (newFile && !oldFile) {
                    this.$refs.upload.active = true;
                }
                if (newFile && oldFile) {
                    // Update file

                    // Start upload
                    if (newFile.active !== oldFile.active) {
                        console.log('Start upload', newFile.active, newFile)

                    // min size
                    // if (newFile.size >= 0 && newFile.size < 100 * 1024) {
                    //     newFile = this.$refs.upload.update(newFile, {error: 'size'})
                    // }
                    }

                    // Upload progress
                    if (newFile.progress !== oldFile.progress) {
                        console.log('progress', newFile.progress, newFile)
                    }

                    // Upload error
                    if (newFile.error !== oldFile.error) {
                        this.$toasted.show('Error Uploading Rule', {
                            type: 'error',
                            duration: 1000
                        })
                        // this.$toasted.show('Error Uploading Rule', { type: 'error '});
                        this.clear();
                        // console.log('error', newFile.error, newFile)
                    }

                    // Uploaded successfully
                    if (newFile.success !== oldFile.success) {
                        this.$toasted.show('Rule Added', {
                            type: 'success',
                            duration: 500
                        });
                        this.model.rules = newFile.response.rules;
                    }
                }
            },
            clear() {
                this.file = [];
                this.content = '';
                this.setState('new')
            },
            setState(state) {
                this.state = state;
            }
        },

    }
</script>

<style lang="scss" scoped>
.card-header {
    min-height: 65px;
}
.delete-btn {
    font-size: 12px;
}
</style>

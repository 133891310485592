<template>
    <standard-box>
        <template #title>
            <h6 class="m-0 font-weight-bold">
                <i class="fa fa-clipboard-check mr-2" />Judging Criteria
            </h6>

            <div class="ml-auto actions fs-12">
                <p-check
                    v-if="hasCriteria"
                    v-model="editMode"
                    class="p-switch p-slim mr-3"
                >
                    Edit
                </p-check>
                <button
                    v-else
                    class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"
                    @click.prevent="createMode = true"
                >
                    <i class="fas fa-plus fa-sm text-white-50" /> New
                </button>
            </div>
        </template>
        <template #body>
            <template v-if="editMode || createMode">
                <WYSIWYG-field
                    v-model="judging_criteria"
                />
            </template>

            <div
                v-else
                class="p-3"
                v-html="judging_criteria"
            />
        </template>
        <template
            v-if="createMode || editMode"
            #footer
        >
            <div
                class="card-footer"
            >
                <div class="d-flex align-items-center justify-content-center">
                    <template v-if="createMode">
                        <button
                            class="mr-2 btn btn-default"
                            @click.prevent="cancelNew"
                        >
                            Cancel
                        </button>
                        <button
                            :disabled="loading"
                            class="btn btn-success"
                            @click.prevent="saveNew"
                        >
                            Save
                        </button>
                    </template>
                    <template v-if="editMode">
                        <button
                            class="btn btn-success m-auto d-block"
                            @click.prevent="saveUpdate"
                        >
                            Save
                        </button>
                    </template>
                </div>
            </div>
        </template>
    </standard-box>
</template>

<script>
    export default {
        props: {
            initCategory: {
                type: Object,
            },
        },
        data() {
            return {
                category: this.initCategory,
                editMode: false,
                createMode: false,
                judging_criteria: '',
                loading: false
            }
        },
        computed: {
            hasCriteria() {
                // return this.category.judging_criteria
                return this.judging_criteria.length > 0;
            }
        },
        mounted () {
            if (this.category.judging_criteria) {
                this.judging_criteria = this.category.judging_criteria.description;
            }
        },
        methods: {
            cancelNew() {
                this.judging_criteria = '';
                this.createMode = false;
            },
            saveNew() {
                this.loading = true;
                axios
                    .post('/api/judging-criteria', {
                        category_id: this.category.id,
                        description: this.judging_criteria
                    })
                    .then(r => {
                        this.judging_criteria = r.data.judging_criteria.description
                        this.$toasted.show('Judging criteria created!', {
                            type: 'success',
                            duration: 1000
                        });
                        this.createMode = false;
                        this.loading = false;
                    })
                    .catch(e => {
                        console.log(e);
                        this.$toasted.show('Error creating judging criteria! Try again later.', {
                            type: 'error',
                            duration: 700
                        });
                        this.loading = false;
                    });
            },
            saveUpdate() {
                this.loading = true;
                axios
                    .patch(`/api/judging-criteria/${this.category.judging_criteria.id}`, {
                        description: this.judging_criteria
                    })
                    .then(r => {
                        this.judging_criteria = r.data.judging_criteria.description
                        this.$toasted.show('Judging criteria updated!', {
                            type: 'success',
                            duration: 1000
                        });
                        this.editMode = false;
                        this.loading = false;
                    })
                    .catch(e => {
                        console.log(e);
                        this.$toasted.show('Error judging criteria! Try again later.', {
                            type: 'error',
                            duration: 700
                        });
                        this.loading = false;
                    });

            }
        },



    }
</script>

<style lang="scss" scoped>
</style>

import draggable from 'vuedraggable'
export const FormBuilderMixin = {
    components: {
            draggable,
    },
    methods: {
        removeField(val) {
            this.schema.fields.splice(val, 1);
        },
        updateForm(val) {
            for (let prop in val) {
                Vue.set(this.schema.fields[val.index], prop, val[prop])
            }
        },
        /**
         * DraggableJS saves the array order using the index.
         * We want to save this new order if the user reordered anything
         * as a new 'order' attribute to persist.
         */
        saveOrderAttributes() {
            this.schema.fields = this.schema.fields.map((field,index) => ({ ...field, order: index }))
        },

        submitCreate(data) {

            if (this.schema.fields.length < 1) {
                this.$swal({
                    type: 'error',
                    text: 'You need at least one field to create a Form.',
                    showConfirmButton: false,
                    timer: 1500
                });
            } else {
                axios
                    .post(this.APIurl, data)
                    .then(r => {
                        if (r.data.success) {
                            if (r.data.redirect) {
                                window.location = r.data.redirect
                            }
                        }
                    })
                    .catch(e => {
                        console.error(e);
                    })

            }

        },

    },

}

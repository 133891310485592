<template>
    <standard-box>
        <template #title>
            <div class="w-100 d-flex align-items-center">
                <h6 class="m-0 font-weight-bold">
                    Entry Preview - {{entry.entry.entry_form.category.name}}
                </h6>
                <button
                    class="ml-auto btn btn-default"
                    @click.prevent="close"
                >
                    <i class="fa fa-times" />
                </button>
            </div>
        </template>
        <template
            class="p-3"
            #body
        >
            <entry-list-preview :entry="entry" />
        </template>
    </standard-box>
</template>

<script>
    export default {
        props: {
            entry: {
                type: Object,
            },
        },
        methods: {
            hydrate() {
                Bus.$emit('hydrate', { type: 'judging-entries' })

            },
            close() {
                this.$emit('close');

            }
        },

    }
</script>

<style lang="scss" scoped>

</style>

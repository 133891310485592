<template>
    <standard-box>
        <template #title>
            <h6 class="m-0 font-weight-bold">
                Entry Preview
            </h6>
            <span class="badge badge-secondary text-capitalize">
                {{ entry.payment_status }}
            </span>
        </template>
        <template
            class="p-3"
            #body
        >
            <div class="p-3">
                <manage-judges-section
                    :entry="entry"
                    @update="hydrate"
                />
            </div>
            <entry-list-preview :entry="entry" />
        </template>
    </standard-box>
</template>

<script>
    export default {
        props: {
            entry: {
                type: Object,
            },
        },
        methods: {
            hydrate() {
                Bus.$emit('hydrate', { type: 'entries' })

            }
        },

    }
</script>

<style lang="scss" scoped>

</style>

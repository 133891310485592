<template>
    <div class="card">
        <div class="card-header d-flex align-items-center">
            File Upload
            <button
                class="ml-auto btn btn-sm"
                @click.prevent="removeField"
            >
                <i class="fa fa-trash text-danger" />
            </button>
        </div>
        <div class="card-body">
            <div class="row">
                <label
                    for=""
                    class="col-sm-2 col-form-label"
                >
                    Name:
                </label>
                <div class="col-sm-10">
                    <input
                        :value="value.value"
                        type="text"
                        class="form-control"
                        :disabled="disabled"
                        @input="update"
                    >
                </div>
            </div>

            <div
                v-if="value.hasOwnProperty('judging_visibility')"
                class="row"
            >
                <div class="col-md-12">
                    <judging-visibility-field v-model="value.judging_visibility" />
                </div>
            </div>
            <div
                v-if="value.hasOwnProperty('required')"
                class="row"
            >
                <div class="col-md-12">
                    <required-validation-field v-model="value.required" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            value: {
                type: [ String, Object ]
            },
            index: {
                type: [ String, Number ]
            },
            disabled: {
                type: Boolean
            }
        },
        data() {
            return {
                data: this.value
            }
        },
        methods: {

            removeField() {
                this.$emit('remove', this.index);
            },
            update(event) {
                this.$emit('input', {
                    value: event.target.value,
                    index: this.index
                })
            }
        },

    }
</script>

<style lang="scss" scoped>

</style>

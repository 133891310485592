<template>
    <div class="row no-gutters align-items-center">
        <div class="col mr-2">
            <div class="text-xs font-weight-bold text-warning text-uppercase mb-1">
                {{ category.name }}
            </div>
            <div class="row no-gutters align-items-center">
                <div class="col-auto">
                    <div class="h5 mb-0 mr-3 font-weight-bold text-warning">
                        TIE
                    </div>
                </div>
            </div>
        </div>
        <div class="col-auto ">
            <i class="fas fa-exclamation-triangle fa-2x text-warning" />
        </div>
    </div>
</template>

<script>
    export default {
        props: ['category', 'results']

    }
</script>

<style lang="scss" scoped>

</style>

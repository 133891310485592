<template>
    <a
        :href="`/admin/results/${initCategory.id}`"
        class="result-card card shadow h-100 py-2"
        :class="borderColor"
    >
        <div class="card-body">
            <template v-if="!loading">
                <div
                    v-if="error"
                    class="text-danger d-flex flex-column align-items-center"
                >
                    <i class="mb-2 fa-2x fa fa-exclamation-triangle" />
                    {{ error }}
                </div>


                <template v-else>
                    <div
                        v-if="!results.judging_complete"
                        class="row no-gutters align-items-center"
                    >
                        <div class="col mr-2">
                            <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                {{ category.name }}
                            </div>
                            <div class="row no-gutters align-items-center">
                                <div class="col-auto">
                                    <div class="h5 mb-0 mr-3 font-weight-bold text-gray-800">
                                        {{ progress.width }}%
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="progress progress-sm mr-2">
                                        <div
                                            class="progress-bar bg-primary"
                                            role="progressbar "
                                            :style="{ width: progress.width +'%' }"
                                            :aria-valuenow="progress.width"
                                            :aria-valuemin="progress.min"
                                            :aria-valuemax="progress.max"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-auto">
                            <i class="fas fa-award fa-2x text-gray-300" />
                        </div>
                    </div>
                    <div v-else>
                        <template v-if="results.is_tie">
                            <category-result-tie
                                :category="category"
                                :result="results"
                            />
                        </template>
                        <div
                            v-else
                            class="row no-gutters align-items-center"
                        >
                            <div class="col mr-2">
                                <div class="text-xs font-weight-bold text-success text-uppercase mb-1">
                                    {{ category.name }}
                                </div>
                                <div class="row no-gutters align-items-center">
                                    <div class="col-auto">
                                        <div class="h5 mb-0 mr-3 font-weight-bold text-success">
                                            Entry #{{ results.winner.id }} - {{ results.winner.company_name }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-auto ">
                                <div class="badge score">
                                    {{ results.winner.total_score }}
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </template>
            <div
                v-else
                class="d-flex align-items-center justify-content-center"
            >
                <moon-loader :color="'#4e73df'" />
            </div>
        </div>
    </a>
</template>

<script>
    import MoonLoader from 'vue-spinner/src/MoonLoader.vue'
    export default {
        components: { MoonLoader },
        props: {
            initCategory: {
                type: Object
            },
        },
        data() {
            return {
                loading: true,
                category: '',
                results: '',
                error: '',
            }
        },
        computed: {
            progress() {
                if (this.judgingComplete) {
                    return {
                        min: 0,
                        max: 100,
                        width: 100
                    }
                }

                if (this.results) {
                    if (this.results.totalJudgingEntries !== 0 && this.results.totalJudgingEntriesNeeded !== 0) {
                        return {
                            min: 1,
                            max: this.results.totalJudgingEntriesNeeded,
                            width: ((this.results.totalJudgedEntries / this.results.totalJudgingEntriesNeeded) * 100).toFixed(0)
                        }
                    }
                }

                return {
                    min: 0,
                    max: 0,
                    width: 0
                }


            },
            judgingComplete() {
                return this.results && this.results.judging_complete
            },
            borderColor() {
                if (this.results && this.results.is_tie) {
                    return 'border-left-warning'
                }

                if (this.error) {
                    return 'border-left-danger'
                }
                if (this.judgingComplete) {
                    return 'border-left-success'
                }


                return 'border-left-primary'

            }
        },
        mounted () {
            this.fetchResults();
        },
        methods: {
            fetchResults() {
                this.error = '';
                axios
                    .get(`/api/results/${this.initCategory.id}`)
                    .then(r => {
                        this.category = r.data.category;
                        this.results = r.data.results;
                        this.loading = false
                    })
                    .catch(e => {
                        this.error = 'Failed To Fetch'
                        console.error(e);
                        this.loading = false;
                    })


            }
        },

    }
</script>

<style lang="scss" scoped>


@import "../../../sass/_variables.scss";
@import "~bootstrap/scss/bootstrap.scss";
.result-card {
    &:before {
        content: ' ';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        box-shadow: 0 10px 50px 0 rgba(0, 0, 0, 0.5);
        opacity: 0;
        transition: opacity 500ms;
    }
    &:hover {
        &:before {
            opacity: 1;
        }
        text-decoration: none !important;
    }
}
.score {
    padding: 1em;
    border: 3px solid;
    border-radius: 50%;
    color: $success;
    font-size: 1em;
}

</style>

<template>
    <div>
        <h2>Rules</h2>
        <p>{{ rules.description }}</p>
        <a
            v-if="rules.path"
            :href="rules.path"
        >
            Download
        </a>
    </div>
</template>

<script>
    export default {
        props: {
            rules: {
                type: Object,
            },
        },

    }
</script>

<style lang="scss" scoped>

</style>

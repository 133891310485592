<template>
    <div>
        <h2 class="text-left">
            Price
        </h2>
        <table class="price-table table table-bordered">
            <tbody>
                <tr
                    v-for="fee in activeFees"
                    :key="fee.id"
                >
                    <td>{{ fee.name }}</td>
                    <td>${{ fee.price_in_dollars }}</td>
                </tr>
                <tr>
                    <td>Total</td>
                    <td>{{ totalCost | currency }} </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    export default {
        props: {
            isAdditional: {
                type: Boolean,
            },
            fees: {
                type: Array
            }
        },
        computed: {
            customFees() {
                return this.fees.filter(fee => fee.type === 'custom');
            },
            coreFees() {
                return this.fees.filter(fee => fee.type !== 'custom');
            },
            activeFees() {
                let core = this.coreFees.filter(fee => !fee.type.includes('additional'));
                if (this.isAdditional) {
                    core = this.coreFees.filter(fee => fee.type.includes('additional'));
                }
                return [...core, ...this.customFees];
            },
            totalCost() {
                return this.activeFees.reduce((a, b) => {
                    return a + b.price
                }, 0)

            }
        },

    }
</script>

<style lang="scss" scoped>

</style>

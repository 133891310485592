import { render, staticRenderFns } from "./ShowYearView.vue?vue&type=template&id=46bc1d53&scoped=true&"
import script from "./ShowYearView.vue?vue&type=script&lang=js&"
export * from "./ShowYearView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46bc1d53",
  null
  
)

export default component.exports
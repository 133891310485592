<template>
    <div>
        <label v-if="label">
            {{ label }}
        </label>
        <trumbowyg
            :value="value"
            :config="config"
            class="form-control"
            :name="name"
            @tbw-change="update"
        />
    </div>
</template>

<script>
    import Trumbowyg from 'vue-trumbowyg';
    import 'trumbowyg/dist/plugins/fontsize/trumbowyg.fontsize.js'
    import 'trumbowyg/dist/ui/trumbowyg.css';
    export default {
        components: { Trumbowyg },
        props: {
            label: {
                type: String,
                required: false
            },
            name: {
                type: String,
                required: false
            },
            value: {
                type: String
            }
        },
        data() {
            return {
                config: {
                                    btns: [
                    ['strong', 'em', 'underline', 'del'],
                    ['foreColor'],
                    ['fontsize'],
                    ['link'],
                    'btnGrp-justify',
                    'btnGrp-lists',
                    ['removeformat', 'undo', 'redo'],
                    ['fullscreen']
                ]

                },

            }
        },
        methods: {
            update(value) {
                this.$emit('input', value.target.value)
            }
        },
    }
</script>

<style>
.trumbowyg-box {
    margin: 0;
    border: 0;
}
    .trumbowyg-editor {
        background: white;
        font-weight: lighter;
    }
</style>

<template>
    <div class="list-group list-group-flush">
        <template v-for="field in entry.fields">
            <div
                v-if="!isHidden(field)"
                :key="field.id"
                class="list-group-item"
            >
                <div class="d-flex">
                    {{ field.question }}:
                    <a
                        v-if="field.public_url"
                        target="_blank"
                        class="ml-auto"
                        :href="field.public_url"
                    >
                        <i class="fa fa-file" /> View File
                    </a>
                    <span
                        v-else
                        class="ml-auto"
                    >
                        {{ field.value }}
                    </span>
                </div>
                <div
                    v-if="field.comments"
                    class="font-weight-light font-italic w-100"
                >
                    {{ field.comments }}
                </div>
            </div>
        </template>
    </div>
</template>

<script>
    export default {
        props: {
            entry: {
                type: Object,
            },
        },
        methods: {
            isHidden(field) {
                if (this.$isAdmin) {
                    return false;
                }

                if (this.$isJudge) {
                    return field.judging_visibility
                }

            }
        },

    }
</script>

<style lang="scss" scoped>

</style>

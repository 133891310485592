<template>
    <standard-box>
        <template #title>
            <h6 class="m-0 font-weight-bold text-default">
                <i class="fa fa-info-circle mr-2" />Users
            </h6>

            <div class="ml-auto actions">
                <a
                    href="/admin/users/create"
                    class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"
                >
                    <i class="fas fa-plus fa-sm text-white-50" />  New
                </a>
            </div>
        </template>
        <template #body>
            <v-client-table
                class="p-4"
                :data="users"
                :columns="columns"
                :options="options"
            >
                <div
                    slot="actions"
                    slot-scope="props"
                    target="_blank"
                    :href="props.row.id"
                    class="d-flex justify-content-center"
                >
                    <a
                        :href="`/admin/users/${props.row.id}/edit`"
                        class="btn btn-default text-gray"
                    >
                        <i class="fa fa-edit" />
                    </a>
                    <button
                        class="btn btn-default text-danger"
                        @click.prevent="deleteUser(props.row.id)"
                    >
                        <i class="fa fa-trash" />
                    </button>
                </div>
            </v-client-table>
        </template>
    </standard-box>
</template>

<script>
    export default {
        props: {
            initUsers: {
                type: Array,
            },
        },
        data() {
            return {
                users: this.initUsers,
                columns: ['name', 'type', 'email', 'actions'],
                options: {}
            }
        },
        methods: {
            deleteUser(id) {
                this.$swal({
                    type: 'error',
                    text: 'Are you sure?',
                    showConfirmButton: true,
                    showCancelButton: true,
                    reverseButtons: true
                }).then(r => {
                    if (r.value) {
                        axios
                            .delete(`/admin/users/${id}`)
                            .then(r => {
                                this.users.splice(this.users.findIndex(user => user.id === id), 1);
                                this.$toasted.show('User Deleted!', {
                                    type: 'success',
                                    duration: 700
                                });
                            })
                            .catch(e => {
                                this.$toasted.show('Error Deleting User', {
                                    type: 'error',
                                    duration: 1500
                                })
                            })
                    }
                })
            }
        },
    }
</script>

<style lang="scss" >
.VueTables__search-field {
    input {
        margin-left: 1em;
    }
    display: flex;
    margin-left: auto;
}

</style>

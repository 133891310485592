<template>
    <div class="d-flex align-items-center">
        <label
            v-if="label"
            class="mb-0"
        >
            {{ label }}
        </label>
        <date-range-picker
            class="ml-md-auto"
            :locale-data="locale"
            :date-range="singleRange"
            :ranges="false"
            opens="left"
            :single-date-picker="true"
            @update="setRange"
        />
    </div>
</template>

<script>
    import DateRangePicker from 'vue2-daterange-picker'
    import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
    export default {
        components: { DateRangePicker },
        props: {
            label: {
                type: String
            },
            range: {
                type: String
            },
        },
        data() {
            return {
                locale: {
                    direction: 'ltr', // direction of text
                    format: 'MMMM D YYYY', // fomart of the dates displayed
                    separator: ' - ', // separator between the two ranges
                    applyLabel: 'Apply',
                    cancelLabel: 'Cancel',
                    weekLabel: 'W',
                    customRangeLabel: 'Custom Range',
                    daysOfWeek: moment.weekdaysMin(), // array of days - see moment documenations for details
                    monthNames: moment.monthsShort(), // array of month names - see moment documenations for details
                    firstDay: 1 // ISO first day of week - see moment documenations for details
                }
           }
        },
        computed: {
            singleRange() {
                if (this.range.length > 0) {
                    return {
                        startDate: moment(this.range),
                        endDate: moment(this.range)
                    }
                } else {
                    return {
                        startDate: moment(),
                        endDate: moment()
                    }
                }
            },
        },
        methods: {
            setRange(data) {
                this.$emit('input', {
                    startDate: moment(data.startDate).format('YYYY-MM-DD'),
                    endDate: moment(data.endDate).format('YYYY-MM-DD')
                });
            }
        },

    }
</script>

<style lang="scss" scoped>

</style>

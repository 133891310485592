<template>
    <div class="row">
        <div class="col-md-12">
            <div class="row">
                <div class="col-md-12">
                    <h1 class="text-center">
                        Create New Year
                    </h1>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <form
                        action="POST"
                        role="form"
                        @submit.prevent="submit"
                    >
                        <div class="form-group">
                            <label>Year</label>
                            <input
                                v-model="data.year"
                                name="year"
                                class="form-control"
                                type="number"
                                maxlength="4"
                            >
                        </div>

                        <date-picker-field
                            :range="data.early_bird_expires_at"
                            :single="true"
                            label="Early Bird Expires At"
                            class="mb-4"
                            @input="setExpiration"
                        />
                        <date-range-picker-field
                            :range="data.entry_period"
                            label="Entry Form Active Range"
                            class="mb-4"
                            @input="setRange"
                        />
                        <div class="actions d-flex align-items-center justify-content-center">
                            <button
                                class="btn btn-default mr-5"
                                @click.prevent="goBack"
                            >
                                Cancel
                            </button>
                            <button class="btn btn-primary">
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            program: {
                type: Object,
            },
        },

        data() {
            return {
                data: {
                    program_id: this.program.id,
                    year: '',
                    early_bird_expires_at: moment().add(7, 'days').format('YYYY-MM-DD'),
                    entry_period: {
                        startDate: moment().startOf('week').format('YYYY-MM-DD'),
                        endDate: moment().endOf('week').format('YYYY-MM-DD')
                    }
                }
            }
        },
        methods: {
            setExpiration(data) {

                this.data.early_bird_expires_at = data.startDate
            },
            goBack() {
                window.location = `/admin/programs/${this.program.id}/`
            },
            setRange(val) {
                this.data.entry_period = val;

console.log(val);
            },
            submit() {
                let year = {
                    year: this.data.year,
                    program_id: this.data.program_id,
                    early_bird_expires_at: this.data.early_bird_expires_at,
                    entry_start_at: moment(this.data.entry_period.startDate).format('YYYY-MM-DD'),
                    entry_end_at: moment(this.data.entry_period.endDate).format('YYYY-MM-DD'),
                }
                axios
                    .post('/api/years', year)
                    .then(r => {
                        if (r.data.success) {
                            if (r.data.redirect) {
                                window.location = r.data.redirect
                            }
                        }
                        console.log(r);
                    })
                    .catch(e => {
                        console.log(e);
                    })


            }
        },

    }
</script>

<style lang="scss" scoped>

</style>

<template>
    <div class="card shadow">
        <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
            <slot name="title" />
        </div>
        <div class="card-body p-0">
            <slot name="body" />
        </div>
        <slot name="footer" />
    </div>
</template>

<script>
    export default {

    }
</script>

<style lang="scss" scoped>

</style>

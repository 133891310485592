<template>
    <standard-box>
        <template #title>
            <h6 class="m-0 font-weight-bold">
                <i class="fa fa-certificate mr-2" /> Categories
            </h6>

            <div class="ml-auto actions">
                <a
                    :href="`/admin/years/${year.id}/categories/create`"
                    class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"
                >
                    <i class="fas fa-plus fa-sm text-white-50" /> New
                </a>
            </div>
        </template>
        <template #body>
            <ul class="list-group list-group-flush">
                <li
                    v-for="category in categories"
                    :key="category.id"
                    class="list-group-item d-flex align-items-center"
                >
                    <a :href="`/admin/categories/${category.id}`">
                        {{ category.name }}
                    </a>
                    <button
                        class="ml-auto btn btn-default text-primary"
                        @click.prevent="clone(category)"
                    >
                        <i class="fa fa-clone" />
                    </button>
                </li>
            </ul>
        </template>
    </standard-box>
</template>

<script>
    export default {
        props: {
            year: {
                type: Object
            },
            initCategories: {
                type: Array,
            },
        },
        data() {
            return {
                categories: this.initCategories
            }
        },

        methods: {
            submitDuplicate(data) {
                console.log(data);

                        return axios
                            .post(`/api/categories/${data.category.slug}/duplicate`, {
                                name: data.name,
                                slug: data.slug
                            }).then(r => {
                                if (r.data.success) {
                                    this.categories.push(r.data.category);
                                    window.location = r.data.redirect;
                                    return r.data;
                                } else {
                                    throw new Error('error');
                                }
                            })
                            .catch(e => {
                                if (e.response.data && e.response.data.errors && e.response.data.errors.slug) {
                                    this.$swal.showValidationMessage(e.response.data.errors.slug)
                                } else {
                                    this.$swal.showValidationMessage(
                                        `Request failed:`
                                    )
                                }
                            })
            },
            clone(category) {
                this.$swal({
                    title: 'Duplicate Category?',
                    text: 'This will copy the Category\'s General Information, Entry Form, and Judging Form',
                    input: 'text',
                    inputPlaceholder: 'Enter a new name',
                    showCancelButton: true,
                    confirmButtonText: 'Next',
                    showLoaderOnConfirm: true,
                    reverseButtons: true
                }).then(r => {
                    if (r.value) {
                        this.$swal({
                            title: 'Enter a new URL',
                            text: 'This must be unique',
                            input: 'text',
                            showCancelButton: true,
                            confirmButtonText: 'Submit',
                            showLoaderOnConfirm: true,
                            reverseButtons: true,
                            preConfirm: (newSlug) => {
                                return this.submitDuplicate({
                                    category: category,
                                    name: r.value,
                                    slug: newSlug
                                });
                            }
                        })
                        .then(r => {
                            if (r.value) {
                                this.$swal({
                                    title: 'Success',
                                    type: 'success',
                                    showConfirmButton: false,
                                    timer: 500
                                });
                            }
                        })

                    }
                })

            },

        },

    }
</script>

<style lang="scss" scoped>

</style>

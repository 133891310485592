<template>
    <div class="card">
        <div class="card-header d-flex align-items-center">
            Radio
            <button
                class="ml-auto btn btn-sm"
                @click.prevent="removeField"
            >
                <i class="fa fa-trash text-danger" />
            </button>
        </div>
        <div class="card-body">
            <div class="mb-3 row">
                <label
                    for=""
                    class="col-sm-2 col-form-label"
                >
                    Name:
                </label>
                <div class="col-sm-10">
                    <input
                        :value="value.value"
                        type="text"
                        class="form-control"
                        :disabled="disabled"
                        @input="update"
                    >
                </div>
            </div>
            <div class="row">
                <label
                    class="col-sm-2 col-form-label"
                    for=""
                >
                    Options:
                </label>
                <div class="col-sm-10">
                    <vue-tags-input
                        v-model="tag"

                        :tags="value.tags"
                        @tags-changed="tagsChange"
                    />
                </div>
            </div>

            <div
                v-if="value.hasOwnProperty('judging_visibility')"
                class="row"
            >
                <div class="col-md-12">
                    <judging-visibility-field v-model="value.judging_visibility" />
                </div>
            </div>
            <div
                v-if="value.hasOwnProperty('required')"
                class="row"
            >
                <div class="col-md-12">
                    <required-validation-field v-model="value.required" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import VueTagsInput from '@johmun/vue-tags-input';
    export default {
        components: { VueTagsInput },
        props: {
            value: {
                type: [ String, Object ]
            },
            index: {
                type: [ String, Number ]
            },
            tags: {
                type: Array
            },
            disabled: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                data: this.value,
                tag: '',
            }
        },
        methods: {
            removeField() {
                this.$emit('remove', this.index);
            },
            tagsChange(val) {
                this.$emit('input', {
                    value: this.value.value,
                    index: this.index,
                    tags: val
                })
            },
            update(event) {
                this.$emit('input', {
                    value: event.target.value,
                    index: this.index
                })
            }
        },

    }
</script>

<style lang="scss" scoped>
.vue-tags-input {
    max-width: 100%;
}
.ti-input {
    border-radius: 0.35rem !important;
    border: 1px solid #d1d3e2 !important;
}
</style>

import Vue from 'vue'
import moment from 'moment';

Vue.filter('first4Chars', str => str.substring(0, 4))

Vue.filter('phone', function (phone) {
    return phone.replace(/[^0-9]/g, '')
        .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
});

Vue.filter('YYYY-MM-DD', function (date) {
    if (moment(date).isValid()) {
        return moment(date).format('YYYY-MM-DD');
    } else {
        return date;
    }
});

Vue.filter('MM/DD/YYYY', function (date) {
    if (moment(date).isValid()) {
        return moment(date).format('MM/DD/YYYY');
    } else {
        return date;
    }
});

Vue.filter('human-readable-month', function (date) {
    if (moment(date).isValid()) {
        return moment(date).format('MMMM YYYY');
    } else {
        return date;
    }
});

Vue.filter('truncate', function(value) {
    if (value.length > 20) {
        value = value.substring(0, 17) + '...';
    }

    return value
});

Vue.filter('currency', function (value) {
    return '$' + parseFloat(value / 100 ).toFixed(2);
});

Vue.filter('trim', function (value) {
    // return value;
    return value.replace(/\s+/g, '');
});

<template>
    <standard-box class="mb-4">
        <template #title>
            <h6 class="m-0 font-weight-bold text-default">
                <i class="fa fa-info-circle mr-2" />General
            </h6>

            <div class="ml-auto actions fs-12">
                <p-check
                    v-model="editMode"
                    class="p-switch p-slim mr-0"
                >
                    Edit
                </p-check>
            </div>
        </template>
        <template #body>
            <div class="list-group list-group-flush">
                <div class="list-group-item flex-md-row flex-column d-flex align-items-md-center">
                    <div class="p-md-2">
                        Name:
                    </div>
                    <div class="ml-md-auto mt-2 mt-md-0">
                        <div
                            v-if="editMode"
                            class="input-group"
                        >
                            <input
                                id="name"
                                v-model="newData.name"
                                type="text"
                                class="form-control"
                            >
                        </div>
                        <div
                            v-else
                            class="ml-auto"
                        >
                            {{ model.name }}
                        </div>
                    </div>
                </div>
                <div class="list-group-item flex-md-row flex-column d-flex align-items-md-center">
                    <div class="p-md-2">
                        Public URL:
                    </div>
                    <div class="ml-md-auto mt-2 mt-md-0">
                        <div
                            v-if="editMode"
                            class="input-group"
                        >
                            <div class="d-none d-sm-block input-group-prepend">
                                <label
                                    class="input-group-text"
                                    for="programURL"
                                >
                                    https://awards.lancasterbuilders.org/
                                </label>
                            </div>
                            <input
                                id="programURL"
                                v-model="newData.slug"
                                type="text"
                                class="form-control"
                                aria-describedby="basic-addon2"
                            >
                        </div>
                        <a
                            v-else
                            target="_blank"
                            :href="'/'+model.slug"
                            class="d-flex text-gray-500 ml-auto"
                        >
                            <span class="truncate d-none d-sm-inline">
                                https://awards.lancasterbuilders.org/
                            </span>
                            <span class="font-weight-bold text-primary">
                                {{ model.slug }}
                            </span>
                        </a>
                    </div>
                </div>
                <div class="list-group-item flex-md-row flex-column d-flex align-items-md-center">
                    <template v-if="editMode">
                        <image-upload-field
                            v-model="newData.logo"
                            field-i-d="logo"
                        >
                            Logo:
                        </image-upload-field>
                    </template>
                    <template v-else>
                        <div class="p-md-2">
                            Logo:
                        </div>
                        <div class="ml-md-auto mt-2 mt-md-0">
                            <img
                                v-if="model.logo_url"
                                width="250"
                                :src="model.logo_url"
                                class="mw-100"
                            >
                            <span v-else>
                                N/A
                            </span>
                        </div>
                    </template>
                </div>
                <div class="list-group-item flex-md-row flex-column d-flex align-items-md-center">
                    <template v-if="editMode">
                        <image-upload-field
                            v-model="newData.featured_image"
                            field-i-d="featuredImage"
                        >
                            <i
                                v-tooltip="'This is the image that will be displayed on the individual Program page next to the description.'"
                                class="text-default fa fa-question-circle"
                            />
                            Featured Image:
                        </image-upload-field>
                    </template>
                    <template v-else>
                        <div class="p-md-2">
                            <i
                                v-tooltip="'This is the image that will be displayed on the individual Program page next to the description.'"
                                class="text-default fa fa-question-circle"
                            />
                            Featured Image:
                        </div>
                        <div class="ml-md-auto mt-2 mt-md-0">
                            <img
                                v-if="model.featured_image_url"
                                width="250"
                                :src="model.featured_image_url"
                                class="mw-100"
                            >
                            <span v-else>
                                N/A
                            </span>
                        </div>
                    </template>
                </div>
                <div class="list-group-item flex-md-row flex-column d-flex align-items-md-center">
                    <template v-if="editMode">
                        <image-upload-field
                            v-model="newData.featured_thumbnail"
                            field-i-d="featuredThumbnail"
                        >
                            <i
                                v-tooltip="'This is the image that will be displayed on the homepage.'"
                                class="text-default fa fa-question-circle"
                            />
                            Featured Thumbnail:
                        </image-upload-field>
                    </template>
                    <template v-else>
                        <div class="p-md-2">
                            <i
                                v-tooltip="'This is the image that will be displayed on the homepage.'"
                                class="text-default fa fa-question-circle"
                            />
                            Featured Thumbnail:
                        </div>
                        <div class="ml-md-auto mt-2 mt-md-0">
                            <img
                                v-if="model.featured_thumbnail_url"
                                width="250"
                                :src="model.featured_thumbnail_url"
                                class="mw-100"
                            >
                            <span v-else>
                                N/A
                            </span>
                        </div>
                    </template>
                </div>
                <div class="list-group-item d-flex flex-wrap align-items-center">
                    <template v-if="editMode">
                        <div class="form-group">
                            <label for="">
                                Description:
                            </label>
                            <WYSIWYG-field
                                v-model="newData.description"
                                class="border"
                            />
                        </div>
                    </template>
                    <template v-else>
                        <div class="w-100">
                            Description:
                        </div>
                        <div
                            class="w-100"
                            v-html="model.description"
                        />
                    </template>
                </div>
            </div>
        </template>
        ss
        <template
            v-if="editMode"
            #footer
        >
            <div
                class="card-footer"
            >
                <button
                    class="btn btn-success m-auto d-block"
                    :disabled="loading"
                    @click.prevent="save"
                >
                    Save
                </button>
            </div>
        </template>
    </standard-box>
</template>

<script>
    export default {
        props: {
            initModel: {
                type: Object,
            },
        },
        data() {
            return {
                newData: {
                    name: this.initModel.name,
                    slug: this.initModel.slug,
                    description: this.initModel.description,
                    logo: [],
                    featured_thumbnail: [],
                    featured_image: []
                },
                model: this.initModel,
                loading: false,
                editMode: false
            }
        },
        watch: {
            editMode(newValue, oldValue) {
                if (!newValue) {
                    this.reset();
                }
            }
        },

        methods: {
            reset() {
                this.newData.name = this.initModel.name;
                this.newData.slug = this.initModel.slug;
                this.newData.description = this.initModel.description;
                this.newData.logo = [];
                this.newData.featured_thumbnail = [];
                this.newData.featured_image = [];
            },
            save() {
                this.loading = true;

                let data = window.jsonToFormData(this.newData);
                data.append('_method', 'PATCH');
                axios
                    .post(`/api/programs/${this.model.id}`, data)
                    .then(r => {
                        this.model.name = this.newData.name;
                        this.model.description = this.newData.description
                        this.model.slug = this.newData.slug
                        this.model.logo_url = r.data.model.logo_url
                        this.model.featured_thumbnail_url = r.data.model.featured_thumbnail_url
                        this.model.featured_image_url = r.data.model.featured_image_url
                        this.reset();
                        this.editMode = false;
                        this.$toasted.show('Updated Program', {
                            type: 'success',
                            duration: 500
                        })
                        this.loading = false;
                    })
                    .catch(e => {
                        console.error(e)
                        this.loading = false;
                        this.$toasted.show('Error Updating Program', {
                            type: 'error',
                            duration: 1000
                        })
                    })
            },
        },

    }
</script>

<style lang="scss" scoped>

</style>

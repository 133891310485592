<template>
    <div class="card shadow mb-4">
        <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
            <h6 class="m-0 font-weight-bold text-default">
                <i class="fa fa-calendar mr-2" />Dates
            </h6>

            <div class="ml-auto actions">
                <p-check
                    v-model="editMode"
                    class="p-switch p-slim"
                >
                    Edit
                </p-check>
            </div>
        </div>
        <div class="card-body p-0">
            <div class="chart-area">
                <div class="list-group list-group-flush">
                    <div class="list-group-item flex-md-row flex-column d-flex align-items-md-center">
                        <div class="p-md-2">
                            Early Bird Fee Expires At
                        </div>
                        <div class="ml-md-auto mt-2 mt-md-0">
                            <template v-if="editMode">
                                <date-range-picker
                                    :locale-data="locale"
                                    :date-range="earlyBirdRange"
                                    :ranges="false"
                                    opens="left"
                                    :single-date-picker="true"
                                    @update="setEarlyBirdExpiration"
                                />
                            </template>
                            <template v-else>
                                <template v-if="year.early_bird_expires_at">
                                    {{ year.early_bird_expires_at | MM/DD/YYYY }}
                                </template>
                                <i
                                    v-else
                                    class="fa fa-warning-exclamation"
                                />
                            </template>
                        </div>
                    </div>
                    <div class="list-group-item flex-md-row flex-column d-flex align-items-md-center">
                        <div class="p-md-2">
                            Entry Form Active
                        </div>
                        <div class="ml-md-auto mt-2 mt-md-0">
                            <template v-if="editMode">
                                <date-range-picker-field
                                    :range="entryFormRange"
                                    @input="setEntryFormRange"
                                />
                            </template>
                            <template v-else>
                                <template v-if="year.early_bird_expires_at">
                                    {{ year.entry_start_at | MM/DD/YYYY }}
                                </template>
                                <i
                                    v-else
                                    class="fa fa-warning-exclamation"
                                />
                                -
                                <template v-if="year.early_bird_expires_at">
                                    {{ year.entry_end_at | MM/DD/YYYY }}
                                </template>
                                <i
                                    v-else
                                    class="fa fa-warning-exclamation"
                                />
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import DateRangePicker from 'vue2-daterange-picker'
    import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
    export default {
        components: { DateRangePicker },
        props: {
            year: {
                type: Object,
            },
        },
        data() {
            return {
                editMode: false,
                locale: {
                    direction: 'ltr', // direction of text
                    format: 'MMMM D YYYY', // fomart of the dates displayed
                    separator: ' - ', // separator between the two ranges
                    applyLabel: 'Save',
                    cancelLabel: 'Cancel',
                    weekLabel: 'W',
                    customRangeLabel: 'Custom Range',
                    daysOfWeek: moment.weekdaysMin(), // array of days - see moment documenations for details
                    monthNames: moment.monthsShort(), // array of month names - see moment documenations for details
                    firstDay: 1 // ISO first day of week - see moment documenations for details
                }
            }
        },
        computed: {
            entryFormRange() {
                return {
                    startDate: this.year.entry_start_at,
                    endDate: this.year.entry_end_at
                }

            },
            earlyBirdRange() {
                return {
                    startDate: this.year.early_bird_expires_at,
                    endDate: this.year.early_bird_expires_at
                }
            }
        },
        methods: {
            setEntryFormRange(data) {
                const newData = {
                    entry_start_at: moment(data.startDate).format('YYYY-MM-DD'),
                    entry_end_at: moment(data.endDate).format('YYYY-MM-DD')
                }
                axios
                    .patch(`/api/years/${this.year.id}`, newData)
                    .then(r => {
                        this.year.entry_start_at = newData.entry_start_at;
                        this.year.entry_end_at = newData.entry_end_at;
                        this.$toasted.show('Entry Range Updated!', {
                            type: 'success',
                            duration: 1000
                        });
                    })
                    .catch(e => {
                        this.$toasted.show('Error Updating Entry Range ', {
                            type: 'error',
                            duration: 1000
                        });
                    })
            },
            setEarlyBirdExpiration(data){
                const newData = moment(data.startDate).format('YYYY-MM-DD');

                axios
                    .patch(`/api/years/${this.year.id}`, {
                        early_bird_expires_at: newData
                    })
                    .then(r => {
                        // Returning a different format (MM-DD-YYYY) because for some reason the date display is off by one if I use YYYY-MM-DD
                        this.year.early_bird_expires_at = moment(data.startDate).format('MM-DD-YYYY');
                        this.$toasted.show('Early Bird Expiration Date Updated!', {
                            type: 'success',
                            duration: 1000
                        });
                    })
                    .catch(e => {
                        this.$toasted.show('Error Updating Early Bird Expiration Date ', {
                            type: 'error',
                            duration: 1000
                        });
                    })
            },
        },

    }
</script>

<style lang="scss" scoped>
.actions {
    font-size: 12px;
}
</style>

<template>
    <div class="row">
        <div class="col-md-6">
            <div class="form-information">
                <form v-if="data">
                    <div class="pb-3 text-center">
                        <p-check
                            v-model="is_additional"
                            name="radio"
                            color="primary"
                            value="now"
                        >
                            This is an additional entry
                        </p-check>
                    </div>

                    <vue-form-generator
                        ref="entryForm"
                        :schema="allFields"
                        :model="model"
                        :options="formOptions"
                        @validated="handleValidation"
                    />
                    <vue-recaptcha
                        ref="recaptcha"
                        size="invisible"
                        sitekey="6Lc2ZrMUAAAAAIkyAWuGR63NOmOHvl7BQn5ymIVh"
                        @verify="onCaptchaVerified"
                        @expired="resetCaptcha"
                    />
                </form>
            </div>
            <a
                :href="`/${program.slug}`"
                class="btn btn-secondary btn-lg back-btn"
            >
                &larr; Back
            </a>
        </div>
        <div class="col-md-6">
            <div id="sticky-form">
                <entry-form-fee
                    :is-additional="is_additional"
                    :fees="data.active_fees"
                />

                <h2 class="text-left">
                    Payment
                </h2>
                <div class="form-group">
                    <p-radio
                        v-model="paymentType"
                        name="radio"
                        color="primary"
                        value="now"
                    >
                        Pay Now
                    </p-radio>
                    <p-radio
                        v-model="paymentType"
                        name="radio"
                        color="primary"
                        value="later"
                    >
                        Please Invoice
                    </p-radio>
                </div>

                <authorize-net-payment
                    v-if="paymentType === 'now'"
                    v-model="opaqueData"
                    @tokenSuccess="validateCaptcha"
                    @tokenFailure="loading = false"
                />
                <p class="email-disclaimer">*Please Note: A complete entry summary will be emailed to the “contact email” that was provided, upon submission.</p>
                <button
                    type="button"
                    :disabled="loading"
                    class="btn btn-primary btn-lg btn-block d-flex justify-content-center"
                    @click="validateForm"
                >
                    <moon-loader
                        v-if="loading"
                        size="30px"
                        :color="'#FFFFFF'"
                    />
                    <template v-else>
                        Submit
                    </template>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import VueRecaptcha from 'vue-recaptcha'
    import MoonLoader from 'vue-spinner/src/MoonLoader.vue'

    export default {
        components: { VueRecaptcha, MoonLoader },
        props: ['program', 'data', 'fields', 'generalFields'],
        data() {
            return {
                is_additional: false,
                loading: false,
                paymentType: 'now',
                isValid: false,
                schema: {
                    fields: this.fields,
                },
                generalSchema: {
                    fields: this.generalFields
                },
                model: {},
                formOptions: {
                    validateAfterLoad: false,
                    validateAfterChanged: false

                },
                opaqueData: {
                    opaqueDataValue: '',
                    opaqueDataDescriptor: ''
                }
            }
        },
        computed: {
            allFields() {
                return {
                    groups: [
                        {
                            legend: 'Company Information',
                            fields: this.generalFields
                        },
                        {
                            legend: 'Entry Details',
                            fields: this.fields
                        }
                    ]
                }
            }
        },
        mounted () {
            if (this.schema.fields) {
                // All General Fields are required
                this.generalSchema.fields.map(field => {
                    field.required = true;
                    field.validator = ["required"]
                });

                // Entry Fields required status comes from the db. We just need to assign the validator.
                this.schema.fields.map(field => {
                    field.validator = ["required"]
                });
                this.attachListenersToFileFields(this.schema);
                this.attachListenersToFileFields(this.generalSchema);
            }
        },
        methods: {
            handleValidation(isValid,errors){
                this.isValid = isValid;
                if (!isValid) {
                    this.loading = false;
                } else {
                    this.loading = true;
                }
            },
            validateForm() {
                this.loading = true;
                console.log(this.loading);

                this.$refs.entryForm.validate();

                if (this.isValid) {
                    if (this.paymentType === 'now') {
                        Bus.$emit('submitPayment');
                    } else {
                        this.validateCaptcha();
                    }
                }
            },
            attachListenersToFileFields(schema) {
                let fileFields = schema.fields.filter(field => field.type === 'upload');

                fileFields.map((fileField) => {
                    fileField.onChanged = function(model, schema, event, instance) {
                        this.model[schema.model] = event.target.files[0];
                    }
                })
            },
            prepareDataForServer() {
                let data = [];
                for (let field in this.model) {
                    let entryField = {};
                    let formField = [...this.generalFields, ...this.fields].find(f => f.model === field);
                    entryField.entry_form_field_id = formField.id;
                    entryField.value = this.model[field]

                    entryField.type = formField.type;
                     entryField.field_id = field;
                    data.push(entryField)
                }
                if (this.paymentType === 'now') {
                    data.opaqueData = this.opaqueData;
                }
                data.is_additional = this.is_additional;
                let formdata = window.jsonToFormData(data);
                return formdata;
            },
            validateCaptcha() {
                this.$refs.recaptcha.execute()
            },
            onCaptchaVerified(token) {
                console.log('onverified', this.loading);
                this.$refs.recaptcha.reset()

                let data = this.prepareDataForServer();
                data.append('g-recaptcha-response', token)
                axios
                    .post(`/api/${this.data.category_id}/entry`, data)
                    .then(r => {
                        this.$swal({
                            type: 'success',
                            text: 'Entry submitted!',
                            showConfirmButton: false,
                            timer: 1500
                        });
                        setTimeout(() => {
                            window.location.href = r.data.redirect
                        }, 200);
                        this.loading = false;

                    })
                    .catch(error => {
                        if (error.response.data.errors) {
                            if (Object.values(error.response.data.errors).find(e => e == 'Invalid file.')) {

                                Vue.toasted.show('One or more of your entries contains an invalid file type.', {
                                    type: 'error',
                                        duration: 1500
                                    })

                            }
                        }
                        Vue.toasted.show('There was an error processing your submission. Try again later.', {
                            type: 'error',
                                duration: 1500
                            })
                        this.loading = false;
                        console.error(error);
                    })
            },
            resetCaptcha() {
                this.loading = false;
                this.$refs.recaptcha.reset()
            }
        },

    }
</script>

<style lang="scss">
    .grecaptcha-badge {
        visibility: hidden !important;
    }
    input[type="file"].form-control {
        background: none;
        border: none;
        box-shadow: none;
    }
    legend {
        color: #C53645;
        font-size: 2rem;
        font-weight: 700;
        margin-bottom: 1rem;
    }
    .form-group.required>label:after {
        color: #C53645;
    }
    button:disabled {
        &:hover {
            background: #004A86;
            border-color: #004A86;
        }
    }
    .email-disclaimer {
        font-size: 12px;
        font-style: italic;
    }
</style>

<template>
    <standard-box>
        <template #title>
            <h6 class="m-0 font-weight-bold text-primary">
                Programs
            </h6>
            <div class="ml-auto actions">
                <a
                    href="/admin/programs/create"
                    class="btn btn-sm btn-primary shadow-sm"
                >
                    <i class="fas fa-plus fa-sm text-white-50" /> Create
                </a>
            </div>
        </template>
        <template #body>
            <div class="chart-area">
                <ul class="list-group list-group-flush">
                    <li
                        v-for="program in programs"
                        :key="program.id"
                        class="d-flex list-group-item"
                    >
                        <a :href="`/admin/programs/${program.id}`">
                            {{ program.name }}
                        </a>
                    </li>
                </ul>
            </div>
        </template>
    </standard-box>
</template>

<script>
    export default {
        props: {
            initPrograms: {
                type: Array,
            },
        },
        data() {
            return {
                programs: this.initPrograms
            }
        },
        methods: {
            deleteProgram(id) {
                this.$swal({
                    type: 'error',
                    text: 'Are you sure?',
                    showConfirmButton: true,
                    showCancelButton: true,
                    reverseButtons: true
                }).then(r => {
                    if (r.value) {
                        axios
                            .delete(`/api/programs/${id}`)
                            .then(r => {
                                this.programs.splice(this.programs.findIndex(program => program.id === id), 1);
                                this.$toasted.show('Program Deleted!', {
                                    type: 'success',
                                    duration: 700
                                });
                            })
                            .catch(e => {
                                console.log(e);
                            })
                    }
                })
            }
        },

    }
</script>

<style lang="scss" scoped>

</style>

<template>
    <div class="card shadow mb-4">
        <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
            <h6 class="m-0 font-weight-bold">
                <i class="fa fa-dollar-sign mr-2" />Fees
            </h6>
        </div>
        <div class="card-body p-0">
            <div class="chart-area">
                <div class="list-group list-group-flush">
                    <div class="list-group-item d-flex">
                        <div class="fee-name">
                            <i
                                v-if="!earlyBirdFee"
                                class="text-warning fa fa-exclamation-triangle"
                            />
                            Early Bird
                        </div>
                        <div class="ml-auto fee-price w-50 text-right align-items-center">
                            <template v-if="earlyBirdFee">
                                {{ earlyBirdFee.price | currency }}
                                <a
                                    href="#"
                                    class="ml-1"
                                    @click.prevent="updateFee(earlyBirdFee)"
                                >
                                    <i class="fa fa-edit" />
                                </a>
                            </template>
                            <template v-else>
                                <a
                                    href="#"
                                    @click.prevent="addNewFee({ type: 'early_bird', name: 'Early Bird'})"
                                >
                                    <i class="fa fa-plus" />
                                </a>
                            </template>
                        </div>
                    </div>
                    <div class="list-group-item d-flex">
                        <div class="fee-name">
                            <i
                                v-if="!earlyBirdAdditionalFee"
                                class="text-warning fa fa-exclamation-triangle"
                            />
                            Early Bird Additional
                        </div>
                        <div class="ml-auto fee-price w-50 text-right align-items-center">
                            <template v-if="earlyBirdAdditionalFee">
                                {{ earlyBirdAdditionalFee.price | currency }}
                                <a
                                    href="#"
                                    class="ml-1"
                                    @click.prevent="updateFee(earlyBirdAdditionalFee)"
                                >
                                    <i class="fa fa-edit" />
                                </a>
                            </template>
                            <template v-else>
                                <a
                                    href="#"
                                    @click.prevent="addNewFee({ type: 'early_bird_additional', name: 'Early Bird Additional'})"
                                >
                                    <i class="fa fa-plus" />
                                </a>
                            </template>
                        </div>
                    </div>
                    <div class="list-group-item d-flex">
                        <div class="fee-name">
                            <i
                                v-if="!regularEntryFee"
                                class="text-warning fa fa-exclamation-triangle"
                            />
                            Regular Entry
                        </div>
                        <div class="ml-auto fee-price w-50 text-right align-items-center">
                            <template v-if="regularEntryFee">
                                {{ regularEntryFee.price | currency }}
                                <a
                                    href="#"
                                    class="ml-1"
                                    @click.prevent="updateFee(regularEntryFee)"
                                >
                                    <i class="fa fa-edit" />
                                </a>
                            </template>
                            <template v-else>
                                <a
                                    href="#"
                                    @click.prevent="addNewFee({ type: 'regular_entry', name: 'Regular Entry'})"
                                >
                                    <i class="fa fa-plus" />
                                </a>
                            </template>
                        </div>
                    </div>
                    <div class="list-group-item d-flex">
                        <div class="fee-name">
                            <i
                                v-if="!regularEntryAdditionalFee"
                                class="text-warning fa fa-exclamation-triangle"
                            />
                            Regular Entry Additional
                        </div>
                        <div class="ml-auto fee-price w-50 text-right align-items-center">
                            <template v-if="regularEntryAdditionalFee">
                                {{ regularEntryAdditionalFee.price | currency }}

                                <a
                                    href="#"
                                    class="ml-1"
                                    @click.prevent="updateFee(regularEntryAdditionalFee)"
                                >
                                    <i class="fa fa-edit" />
                                </a>
                            </template>
                            <template v-else>
                                <a
                                    href="#"
                                    @click.prevent="addNewFee({ type: 'regular_entry_additional', name: 'Regular Entry Additional'})"
                                >
                                    <i class="fa fa-plus" />
                                </a>
                            </template>
                        </div>
                    </div>
                    <div class="list-group-item d-flex align-items-center">
                        <div class="fee-name">
                            Custom Fees
                        </div>
                        <div class="ml-auto fee-price d-flex">
                            <a
                                href="#"
                                @click.prevent="addCustomFee()"
                            >
                                <i class="fa fa-plus" />
                            </a>
                        </div>
                    </div>
                    <div
                        v-for="fee in customFees"
                        :key="fee.id"
                        class="list-group-item d-flex align-items-center ml-3"
                    >
                        <div
                            class="fee-name"
                            v-text="fee.name"
                        />
                        <div class="ml-auto fee-price">
                            {{ fee.price | currency }}
                            <a
                                href="#"
                                class="ml-3"
                                @click.prevent="deleteFee(fee)"
                            >
                                <i class="text-danger fa fa-trash" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            year: {
                type: Object,
            },
        },
        data() {
            return {
                fees: []
            }
        },

        computed: {
            earlyBirdFee() {
                return this.fees.find(fee => fee.type === 'early_bird');
            },
            earlyBirdAdditionalFee() {
                return this.fees.find(fee => fee.type === 'early_bird_additional');
            },
            regularEntryFee() {
                return this.fees.find(fee => fee.type === 'regular_entry');
            },
            regularEntryAdditionalFee() {
                return this.fees.find(fee => fee.type === 'regular_entry_additional');
            },
            customFees() {
                return this.fees.filter(fee => fee.type === 'custom');
            }
        },
        mounted () {
            if (this.year.fees) {
                this.fees = this.year.fees
            }
        },
        methods: {
            addCustomFee() {
                this.$swal({
                    input: 'text',
                    title: 'Enter a name',
                    showCancelButton: true,
                    confirmButtonText: 'Next',
                    reverseButtons: true
                })
                .then(r => {
                    if (r.value) {
                        this.addNewFee({
                            name: r.value,
                            type: 'custom'
                        });
                    }
                })
            },
            addNewFee(data) {
                this.$swal({
                    title: 'Enter amount',
                    input: 'text',
                    inputPlaceholder: '0.00',
                    showCancelButton: true,
                    confirmButtonText: 'Save',
                    showLoaderOnConfirm: true,
                    reverseButtons: true,
                    preConfirm: (price) => {
                        data.year_id = this.year.id;
                        data.price = price * 100;
                        return axios
                            .post(`/api/${this.year.id}/fees`, data ).then(r => {
                                if (r.data.success) {
                                    return r.data;
                                } else {
                                    throw new Error('error');
                                }
                            })
                            .catch(e => {
                                this.$swal.showValidationMessage(
                                    `Request failed:`
                                )
                            })
                    },
                }).then(r => {
                    if (r.value) {
                        this.fees.push(r.value.fee)
                        this.$swal({
                            title: 'Success',
                            type: 'success',
                            showConfirmButton: false,
                            reverseButtons: true,
                            timer: 500
                        });
                    }
                })
            },
            updateFee(fee) {
                this.$swal({
                    title: 'Enter amount',
                    input: 'text',
                    inputPlaceholder: '0.00',
                    showCancelButton: true,
                    confirmButtonText: 'Save',
                    showLoaderOnConfirm: true,
                    preConfirm: (price) => {
                        fee.year_id = this.year.id;
                        fee.price = price * 100;
                        return axios
                            .patch(`/api/fees/${fee.id}`, fee ).then(r => {
                                if (r.data.success) {
                                    return r.data;
                                } else {
                                    throw new Error('error');
                                }
                            })
                            .catch(e => {
                                this.$swal.showValidationMessage(
                                    `Request failed:`
                                )
                            })
                    },
                }).then(r => {
                    if (r.value) {
                        let fee = this.fees.find(fee => fee.id === r.value.fee.id);
                        fee = r.value.fee;
                        // this.fees.push(r.value.fee)
                        this.$swal({
                            title: 'Success',
                            type: 'success',
                            showConfirmButton: false,
                            timer: 500
                        });
                    }
                })
            },
            deleteFee(fee) {
                this.$swal({
                    type: 'warning',
                    text: 'Are you sure?',
                    showConfirmButton: true,
                    showCancelButton: true,
                    reverseButtons: true
                }).then(r => {
                    if (r.value) {
                        axios
                            .delete(`/api/fees/${fee.id}`)
                            .then(r => {
                                this.fees = this.fees.filter(f => f.id !== fee.id);
                            })
                            .catch(e => {
                                console.log(e);
                            })
                    }
                })

            }
        },

    }
</script>

<style lang="scss" scoped>

.fee-name {
    width: 45%;
}
</style>

<template>
    <div
        class="details-box card shadow pr-3 pl-3 pt-4 pb-4 text-center rounded"
        :class="{ 'bg-white': program.general_form, 'bg-gray-300': !program.general_form }"
    >
        <div class="form-action mb-1">
            <template v-if="program.general_form">
                <a :href="`/admin/general-forms/${program.general_form.id}/edit`">
                    <i class="fa fa-edit fs-2" />
                </a>
            </template>
            <template v-else>
                <a :href="`/admin/programs/${program.id}/general-forms/create`">
                    <i class="fa fa-plus" />
                </a>
            </template>
        </div>
        <div class="title">
            General Entry Fields
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            program: {
                type: Object,
            },
        },


    }
</script>

<style lang="scss" scoped>
.entry-details {
    font-size: 1em;
}
.details-box {
    .fa-plus {
        font-size: 3em;
    }
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    min-height: 150px;
}
</style>

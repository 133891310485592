=<template>
    <div>
        <template v-for="(entryCategory,index) in sortedEntries">
            <accordion-box
                :key="index"
                class="mb-3"
            >
                <template #title>
                    <div class="d-flex flex-column flex-md-row align-text-center">
                        <ul class="header-title d-flex flex-column flex-md-row m-0 font-weight-bold text-primary">
                            <li class="mr-2">
                            <span class="badge badge-primary">
                                <i class="fa fa-award" />
                                {{ entryCategory[0].entry_form.category.year.program.name }}
                            </span>
                            </li>

                            <li>
                            <span class="badge badge-default">
                                <i class="fa fa-calendar-alt" />
                                {{ entryCategory[0].entry_form.category.year.year }}
                            </span>
                            </li>
                            <li>
                            <span class="badge badge-default">
                                <i class="fa fa-certificate" />
                                {{ entryCategory[0].entry_form.category.name }}
                            </span>
                            </li>
                        </ul>

                    </div>
                </template>
                <template #body>
                    <ul class="list-group list-group-flush">
                        <li
                            v-for="entry in entryCategory"
                            :key="entry.id"
                            class="list-group-item"
                        >
                            <a
                                class="d-flex align-items-center"
                                href="#"
                                @click.prevent="openModal(entry)"
                            >
                            <span class="badge badge-light font-italic text-gray-700 mr-2">
                                {{ entry.created_at | MM/DD/YYYY }}
                            </span>
                                #{{ entry.id }} - {{ entry.company_name }}
                                <span
                                    class="badge ml-auto"
                                    :class="{ 'badge-warning': entry.judges.length === 0, 'badge-light' : entry.judges.length > 0}"
                                >
                                <i class="fa fa-gavel" /> {{ entry.judges.length }}
                            </span>
                            </a>
                        </li>
                    </ul>
                </template>
                <template #footer>
                    <div class="card-footer text-center">
                        <a
                            :href="`/admin/entries/${entryCategory[0].entry_form.category.slug}/export`"
                            class="btn btn-secondary ml-md-auto fs-12 btn-icon-split p-0 btn-sm"
                        >
                        <span class="icon text-white-50">
                            <i class="fas fa-download" />
                        </span>
                            <span class="text">
                            Export
                        </span>
                        </a>

                    </div>
                </template>
            </accordion-box>
        </template>
        <template v-if="!entries">
            <div class="alert alert-warning text-center">
                <i class="fa fa-frown" />
                No entries have been submitted
            </div>
        </template>
    </div>
</template>

<script>
import AdminEntryModal from './AdminEntryModal.vue'
export default {
    props: {
        initEntries: {
            type: [ Object, Array ]
        },
    },
    data() {
        return {
            entries: this.initEntries
        }
    },
    computed: {
        sortedEntries() {
            let data = Object.values(this.entries);
            data.reverse()
            return data;
        },
    },
    mounted () {
        Bus.$on('hydrate', data => {
            if (data.type === 'entries') {
                this.fetchEntries();
            }
        })
        ;
    },
    methods: {
        fetchEntries() {
            axios
                .get('/api/entries')
                .then(r => {
                    this.entries = r.data
                })
                .catch(e => [
                    console.error(e)
                ])

        },
        openModal(entry) {
            this.$modal.show(AdminEntryModal, {
                entry: entry
            }, {
                scrollable: true,
                width: '80%',
                height: 'auto'
            });

        },
    },

}
</script>

<style lang="scss" scoped>

a:hover {text-decoration: none}
.header-title {
    padding: 0;
    list-style: none;
    //   overflow: hidden;
    //   font: 18px Sans-Serif;
}
</style>

<template>
    <standard-box class="mb-4">
        <template #title>
            <h6 class="m-0 font-weight-bold">
                <i class="fa fa-calendar-alt mr-2" />Years
            </h6>
            <div class="ml-auto actions">
                <a
                    :href="`/admin/programs/${program.id}/years/create`"
                    class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"
                >
                    <i class="fas fa-plus fa-sm text-white-50" /> New
                </a>
            </div>
        </template>
        <template #body>
            <div class="list-group list-group-flush">
                <div
                    v-for="year in years"
                    :key="year.id"
                    class="d-flex align-items-center list-group-item"
                >
                    <div>
                          <span
                              class="published-indicator mr-2"
                              :class="{ active: year.published }"
                          />
                        <a :href="`/admin/years/${year.id}`">
                            {{ year.year }}
                        </a>
                    </div>

                    <div class="clone-delete-container">
                        <button
                            class="ml-auto btn btn-default text-primary"
                            @click.prevent="deleteYear(year.id)"
                        >
                            <i class="fa fa-trash"></i>
                        </button>
                        <button
                            class="ml-auto btn btn-default text-primary"
                            @click.prevent="clone(year)"
                        >
                            <i class="fa fa-clone"/>
                        </button>
                    </div>
                </div>
            </div>
        </template>
    </standard-box>
</template>

<script>
    export default {
        props: {
            program: {
                type: Object
            },
            initYears: {
                type: Array,
            },
        },
        data() {
            return {
                years: this.initYears
            }
        },
        methods: {
            clone(year) {
                this.$swal({
                    title: 'Duplicate Year?',
                    text: 'Enter in a new year',
                    input: 'number',
                    inputPlaceholder: '2020',
                    showCancelButton: true,
                    confirmButtonText: 'Save',
                    showLoaderOnConfirm: true,
                    reverseButtons: true,
                    allowOutsideClick: () => !this.$swal.isLoading(),
                    preConfirm: (newYear) => {
                        return axios
                            .post(`/api/years/${year.id}/duplicate`, {
                                year: newYear
                            }).then(r => {
                                if (r.data.success) {
                                    return r.data;
                                } else {
                                    throw new Error('error');
                                }
                            })
                            .catch(e => {
                                if (e.response.data && e.response.data.errors && e.response.data.errors.year) {
                                    this.$swal.showValidationMessage(e.response.data.errors.year)
                                } else {
                                    this.$swal.showValidationMessage(
                                        `Request failed:`
                                    )
                                }
                            })
                    },
                }).then(r => {
                    if (r.value) {
                        this.years.push(r.value.year);
                        this.$swal({
                            title: 'Success',
                            type: 'success',
                            showConfirmButton: false,
                            timer: 500
                        });
                        window.location = r.value.redirect;
                    }
                })

            },
            categoriesList(year) {
                if (year.categories && year.categories.length > 0) {
                    return year.categories.map(category => category.name).toString();
                } else {
                    return 'N/A'
                }

            },
            deleteYear(year) {
                console.log(year);
                this.$swal({
                    type:'warning',
                    text: 'Delete Year?',
                    showConfirmButton: true,
                    showCancelButton: true,
                    reverseButtons: true
                }).then(r => {
                    if (r.value) {
                        axios
                            .delete(`/api/years/${year}/delete`)
                            .then(r => {
                                this.$swal({
                                    title: 'Success',
                                    type: 'success',
                                    showConfirmButton: false,
                                    timer: 500
                                });
                            })
                            .catch(e => {
                                console.log(e);
                            })
                    }
                })
            }
        },

    }
</script>

<style lang="scss" scoped>
@import "../../../sass/_variables.scss";
@import '~bootstrap/scss/bootstrap';
a:hover {
    text-decoration: none;
}
@media (max-width: 700px ) {
    .category-list {
        flex-direction: column;
    }
}
.category-item {
    flex: 1 0 50%;
    font-size: 13px;
}

.published-indicator {
    background: $danger;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: inline-block;
    &.active {
        background: $success;
    }
}

.clone-delete-container {
    display: flex;
    align-items: center;
    .trash-can {
        color: #004A86 !important;
        cursor: pointer;
    }
}

.list-group-item {
    justify-content: space-between;
}

</style>

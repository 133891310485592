<template>
    <standard-box>
        <template #title>
            <h6 class="m-0 font-weight-bold">
                <i class="fa fa-gavel mr-2" />Judges
            </h6>

            <div class="ml-auto actions fs-12">
                <p-check
                    v-model="editMode"
                    class="p-switch p-slim mr-3"
                >
                    Edit
                </p-check>
            </div>
        </template>
        <template #body>
            <div class="list-group list-group-flush">
                <div
                    v-if="assignedJudges.length < 1"
                    class="list-group-item font-italic text-center"
                >
                    This category does not have any Judges assigned.
                </div>
                <div
                    v-for="judge in assignedJudges"
                    :key="judge.id"
                    class="list-group-item d-flex align-items-center"
                >
                    <i class="fa fa-user mr-2" />{{ judge.name }}
                    <a
                        v-if="editMode"
                        href="#"
                        class="ml-auto fs-12 pt-0 pb-0 btn btn-default text-danger"
                        @click.prevent="removeJudge(judge.id)"
                    >
                        <i class="fa fa-trash" />
                    </a>
                </div>
            </div>
        </template>
        <template
            v-if="editMode"
            #footer
        >
            <div
                class="card-footer"
            >
                <div class="new-field-row">
                    <div class="d-flex align-items-center flex-md-row flex-column justify-content-between">
                        <v-select
                            v-model="selectedJudge"
                            placeholder="Select a Judge"
                            :select-on-tab="true"
                            class="w-md-75 w-100"
                            label="name"
                            :options="availableJudges"
                        />
                        <button
                            class="btn btn-sm btn-primary border-primary ml-md-5 w-md-50 mt-3 mt-md-0 w-100"
                            style="border: 2px solid;"
                            :disabled="disabled"
                            @click.prevent="addNewJudge()"
                        >
                            <i class="fa fa-plus" /> Assign New Judge
                        </button>
                    </div>
                </div>
            </div>
        </template>
    </standard-box>
</template>

<script>
    export default {
        props: {
            entry: {
                type: Object
            },
            initJudges: {
                type: Array
            },
        },
        data() {
            return {
                selectedJudge: '',
                editMode: false,
                assignedJudges: [],
                allJudges: [],
                disabled: true,
                fetchJudgesAPI: '/api/users?judges=1'
            }
        },
        computed: {
            /**
             * Filter out any Judges that have already been selected
             */
            availableJudges() {
                let categoryJudgeIds = this.assignedJudges.map(judge => judge.id);
                return this.allJudges.filter(judge => !categoryJudgeIds.includes(judge.id));
            }
        },
        watch: {
            selectedJudge(newValue, oldValue) {
                if (newValue) {
                    this.disabled = false;
                } else  {
                    this.disabled = true;

                }


            }
        },
        mounted () {
            if (this.entry && this.entry.judges) {
                this.assignedJudges = this.entry.judges
            }
            this.fetchJudges();
        },
        methods: {
            removeJudge(id) {

                this.$swal({
                    type: 'error',
                    text: 'Are you sure?',
                    showConfirmButton: true,
                    showCancelButton: true,
                    reverseButtons: true
                }).then(r => {
                    if (r.value) {
                        let data = {
                            judge_id: id
                        }

                        if (this.entry) {
                            data.entry_id = this.entry.id
                        }
                        axios
                            .post('/api/judges/unassign', data)
                            .then(r => {
                                this.$emit('update');

                                this.assignedJudges.splice(this.assignedJudges.findIndex(user => user.id === id), 1);
                                this.$toasted.show('Judge Removed!', {
                                    type: 'success',
                                    duration: 700
                                });
                            })
                            .catch(e => {
                                this.$toasted.show('Error Removing Judge!', {
                                    type: 'error',
                                    duration: 700
                                });
                                console.log(e);
                            })
                    }
                })
            },
            addNewJudge() {
                // Double check that a judge has actually been selected by the user
                if (this.selectedJudge.hasOwnProperty('id')) {
                    let data = {
                        judge_id: this.selectedJudge.id
                    }

                    if (this.entry) {
                        data.entry_id = this.entry.id
                    }
                    this.disabled = true;
                    axios
                        .post('/api/judges/assign', data)
                        .then(r => {
                            this.$emit('update');
                            this.assignedJudges.push(this.selectedJudge);
                            this.selectedJudge = null;
                            this.$toasted.show('Judge Assigned!', {
                                type: 'success',
                                duration: 700
                            });
                            this.disabled = false;
                        })
                        .catch(e => {
                            this.$toasted.show('Error Assigning Judge', {
                                type: 'error',
                                duration: 1500
                            })
                            this.disabled = false;
                        })
                }
            },
            fetchJudges() {
                let endpoint = this.fetchJudgesAPI;
                if (this.entry) {
                    endpoint = endpoint + `entry=${this.entry.id}`
                }
                axios
                    .get(endpoint)
                    .then(r => {
                        this.allJudges = r.data;
                    })
                    .catch(e => {
                        console.error(e)
                    })
            }
        },

    }
</script>

<style lang="scss" scoped>
</style>

<template>
    <div>
        <template v-if="!loading">
            <template v-for="(entryCategory,index) in entries">
                <accordion-box
                    :key="index"
                    class="mb-3"
                >
                    <template #title>
                        <div class="d-flex align-text-center">
                            <ul class="header-title m-0 font-weight-bold text-primary">
                                <li class="mr-2">
                                    <span class="badge badge-primary">
                                        <i class="fa fa-award" />

                                        {{ entryCategory[0].entry_form.category.year.program.name }}
                                    </span>
                                </li>

                                <li>
                                    <span class="badge badge-default">
                                        <i class="fa fa-calendar-alt" />
                                        {{ entryCategory[0].entry_form.category.year.year }}
                                    </span>
                                </li>
                                <li>
                                    <span class="badge badge-default">
                                        <i class="fa fa-certificate" />
                                        {{ entryCategory[0].entry_form.category.name }}
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </template>
                    <template #body>
                        <ul class="list-group list-group-flush">
                            <li
                                v-for="entry in entryCategory"
                                :key="entry.id"
                                class="list-group-item"
                            >
                                <a
                                    v-if="!entry.has_been_judged"
                                    class="d-flex align-items-center"
                                    :href="`/judge/entries/${entry.id}`"
                                >
                                    <span class="badge badge-light font-italic text-gray-700 mr-2">
                                        {{ entry.created_at | MM/DD/YYYY }}
                                    </span>
                                    Entry
                                </a>
                                <div
                                    v-else
                                    class="d-flex align-items-center"
                                >
                                    <i class="text-success fa fa-check-circle" />
                                    <span class="badge badge-light font-italic text-gray-700 mr-2">
                                        {{ entry.created_at | MM/DD/YYYY }}
                                    </span>
                                    Entry
                                    <div class="ml-auto d-flex align-items-center">
                                        <div class="badge badge-success mr-2">
                                            {{ totalScore(entry) }}
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </template>
                </accordion-box>
            </template>
            <template v-if="entries && entries.length === 0">
                <div class="alert alert-warning text-center">
                    <i class="fa fa-exclamation-triangle mr-2" />You do not have any available Entries.
                </div>
            </template>
        </template>

        <template v-else>
            <div class="text-center text-italic">
                <pulse-loader :color="'#4e73df'" /> Loading Your Entries
            </div>
        </template>
    </div>
</template>

<script>
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
    export default {
        components: { PulseLoader },

        props: {
        },
        data() {
            return {
                loading: true,
                entries: []
            }
        },
        mounted () {
            this.fetchEntries();
            Bus.$on('hydrate', data => {
                if (data.type === 'entries') {
                    this.fetchEntries();
                }
            });
        },
        methods: {
            totalScore(entry) {
                let judgingEntry = entry.judging_entries.find(e => e.judge_id === Laravel.user.id)
                return judgingEntry.total_score
            },
            fetchEntries() {
                axios
                    .get('/api/my-entries')
                    .then(r => {
                        if (r.data.entries) {
                            this.entries = r.data.entries
                        }
                        this.loading = false;
                    })
                    .catch(e => {
                        console.error(e)
                        this.loading = false
                    })

            },
        },

    }
</script>

<style lang="scss" scoped>
    a:hover {text-decoration: none}
    .header-title {
        padding: 0;
        display: flex;
        list-style: none;
    }
</style>

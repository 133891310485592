<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <h1 class="text-center">
                    Create Category
                </h1>
            </div>
        </div>
        <form
            action="#"
            role="form"
            @submit.prevent="submit"
        >
            <div class="form-group">
                <label>Name</label>
                <input
                    v-model="data.name"
                    name="name"
                    class="form-control"
                    type="text"
                >
            </div>
            <div class="form-group">
                <label for="">
                    URL
                </label>
                <div
                    class="input-group"
                >
                    <div class="input-group-prepend">
                        <label
                            class="input-group-text"
                            for="programURL"
                        >
                            https://awards.lancasterbuilders.org/{{ year.program.slug }}/
                        </label>
                    </div>

                    <input
                        id="programURL"
                        v-model="data.slug"
                        name="slug"
                        class="form-control"
                        type="text"
                    >
                </div>
            </div>
            <div class="form-group">
                <label for="description">
                    Description
                </label>
                <WYSIWYG-field
                    v-model="data.description"
                    class="border"
                />
            </div>

            <div class="actions d-flex align-items-center justify-content-center">
                <button
                    class="btn btn-default mr-5"
                    @click.prevent="goBack"
                >
                    Cancel
                </button>
                <button class="btn btn-primary">
                    Submit
                </button>
            </div>
        </form>
    </div>
</template>

<script>
    export default {
        props: {
            year: {
                type: Object,
            },
        },

        data() {
            return {
                data: {
                    name: '',
                    description: '',
                    year_id: this.year.id
                }
            }
        },
        methods: {
            goBack() {
                window.location = `/admin/years/${this.year.id}`
            },
            submit() {
                axios
                    .post('/api/categories', this.data)
                    .then(r => {
                        if (r.data.success) {
                            if (r.data.redirect) {
                                window.location = r.data.redirect
                            }
                        }
                        console.log(r);
                    })
                    .catch(e => {
                        console.log(e);
                    })


            }
        },

    }
</script>

<style lang="scss" scoped>

</style>

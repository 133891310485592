<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <h1 class="mb-0 text-center">
                    <i class="fa fa-award mr-3" />{{ program.name }}
                </h1>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <div class="mt-4 mb-4 justify-content-around justify-content-md-end status-buttons d-flex">
                    <publish-button
                        :model="program"
                        model-type="programs"
                        :published="program.published"
                        @update="updateProgram"
                    />
                    <judging-status-button
                        v-model="program.judging_status"
                        :program="program"
                        class=""
                    />
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <program-general-information :init-model="program" />
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <year-list
                    :program="program"
                    :init-years="program.years"
                />
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <general-form-details
                    class="mb-4"
                    :program="program"
                />
            </div>
        </div>
        <div class="row">
            <div class="col">
                <manage-rules-section :init-model="program" />
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            initProgram: {
                type: Object,
            },
        },
        data() {
            return {
                program: this.initProgram
            }
        },
        methods: {
            updateProgram(data) {
                this.program = data;
            }
        },

    }
</script>

<style lang="scss" scoped>
</style>

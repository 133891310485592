<template>
    <p-check
        class="p-default p-curve"
        color="success"
        off-color="danger"
        :checked="judgingStatus"
        toggle
        @click.native.prevent="toggleStatus"
    >
        Judging Enabled
        <label slot="off-label">
            Judging Disabled
        </label>
    </p-check>
</template>

<script>
    export default {
        model: {
            prop: 'judgingStatus',
            event: 'input'
        },
        props: {
            program: {
                type: Object
            },
            judgingStatus: {
                type: [ Boolean, Number ]
            }
        },
        methods: {
            toggleStatus() {
                axios
                    .post(`/api/programs/${this.program.id}/judging-status`, {
                        enabled: !this.judgingStatus
                    })
                    .then(r => {
                        this.$emit('input', !this.judgingStatus)
                    })
                    .catch(e => {
                        this.$toasted.show('Error Updating Judging Status', {
                            type: 'error',
                            duration: 1500
                        });
                    })
            },
        },


    }
</script>

<style lang="scss" scoped>

</style>

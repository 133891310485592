<template>
    <div>
        <div
            v-for="program in programs"
            :key="program.id"
            class="row"
        >
            <div v-if="program.years[0]" class="col-md-12">
                <h1 class="mb-4 header-title d-flex align-items-center flex-md-row flex-column list-unstyled m-0 font-weight-bold text-primary">
                    <span class="mr-md-2">
                        <i class="fa fa-award" />
                        {{ program.name }}
                    </span>

                    <span class="mt-2 mt-md-0 badge badge-default">
                        <i class="fa fa-calendar-alt" />
                        {{ program.years[0].year }}
                    </span>
                </h1>
            </div>
            <div class="col-md-12">
                <div class="row" v-if="program.years[0]">
                    <div
                        v-for="category in program.years[0].categories"
                        :key="category.id"
                        class="col-lg-4 mb-4"
                    >
                        <category-result-card :init-category="category" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            programs: {
                type: Array,
            },
        },
    }
</script>

<style lang="scss" scoped>
.header-title {
    padding-left: .3em;
}

</style>

<template>
    <div class="d-flex align-items-center">
        <label
            v-if="label"
            class="mb-0"
        >
            {{ label }}
        </label>
        <date-range-picker
            :date-range="range"
            :locale-data="locale"
            opens="left"
            class="ml-md-auto range-picker"
            @update="setRange"
        >
            <div
                slot="input"
                class="d-flex align-items-center justify-content-lg-between"
            >
                <i class="fa fa-calendar mr-2" />
                <div class="">
                    {{ humanWeek }}
                </div>
            </div>
        </date-range-picker>
    </div>
</template>

<script>
    import DateRangePicker from 'vue2-daterange-picker'
    import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
    export default {
        components: { DateRangePicker },
        props: {
            label: {
                type: String
            },
            range: {
                type: Object,
            },
        },
        data() {
            return {
                locale: {
                    direction: 'ltr', // direction of text
                    format: 'MMMM D YYYY', // fomart of the dates displayed
                    separator: ' - ', // separator between the two ranges
                    applyLabel: 'Apply',
                    cancelLabel: 'Cancel',
                    weekLabel: 'W',
                    customRangeLabel: 'Custom Range',
                    daysOfWeek: moment.weekdaysMin(), // array of days - see moment documenations for details
                    monthNames: moment.monthsShort(), // array of month names - see moment documenations for details
                    firstDay: 1 // ISO first day of week - see moment documenations for details
                }
           }
        },
        computed: {
            week() {
               return {
                    startDate: moment(this.range.startDate).toDate(),
                    endDate: moment(this.range.endDate).toDate()
               }
            },
            humanWeek() {
                let startDate = moment(this.range.startDate);
                let startMonth = startDate.format('MM');
                let endDate = moment(this.range.endDate);
                let endMonth = endDate.format('MM');

                let date = startDate.format('MMMM D') ;
                date += ' - ';
                if (startMonth !== endMonth) {
                    date += endDate.format('MMMM') + " "
                }
                date += moment(this.range.endDate).format('D');
                date += ', ' + moment(this.range.startDate).format('YYYY')
                return date;
            }
        },
        methods: {
            setRange(data) {
                // this.$emit('input', {
                //     startDate: moment(data.startDate).toDate(),
                //     endDate: moment(data.endDate).toDate()
                // });
                this.$emit('input', {
                    startDate: moment(data.startDate).format('MM-DD-YYYY'),
                    endDate: moment(data.endDate).format('MM-DD-YYYY')
                });
            }
        },

    }
</script>

<style>
.range-picker .form-control {
    /* background: transparent;
    cursor: pointer;
    border: 0;
    font-size: 22.5px;
    color: white;
    height: 100%;
    padding: 0; */
}
</style>

<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <h1 class="text-center mb-0">
                    <i class="fa fa-certificate mr-2" />{{ category.name }}
                </h1>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="mt-4 mb-4 justify-content-center justify-content-md-end status-buttons d-flex">
                    <publish-button
                        :model="category"
                        model-type="categories"
                        :published="category.published"
                        @update="updateCategory"
                    />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <category-general-information
                    class="mb-4"
                    :init-model="category"
                />
            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <entry-form-details :category="category" />
            </div>
            <div class="col-md-6">
                <judging-form-details
                    class="mt-4 mt-md-0"
                    :category="category"
                />
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <manage-judging-criteria-section
                    class="mt-4"
                    :init-category="category"
                />
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            initCategory: {
                type: Object,
            },
        },
        data() {
            return {
                category: this.initCategory
            }
        },
        methods: {
            updateCategory(data) {
                this.category = data;
            }
        },

    }
</script>

<style lang="scss" scoped>

</style>

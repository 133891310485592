<template>
    <p-check
        :checked="published"
        class="p-default p-curve"
        color="success"
        off-color="danger"
        toggle
        @click.native.prevent="togglePublishStatus"
    >
        Public
        <label slot="off-label">
            Private
        </label>
    </p-check>
</template>

<script>
    export default {
        props: {
            modelType: {
                type: String
            },
            model: {
                type: Object,
            },
            published: {
                type: [ Boolean, Number ]
            }
        },
        data() {
            return {
                loading: false,
                data: this.model
            }
        },
        methods: {
            togglePublishStatus() {
                if (!this.loading) {
                    this.submitUpdate(!this.published);
                }
            },
            submitUpdate(status) {
                this.loading = true;
                    axios
                        .patch(`/api/${this.modelType}/${this.model.id}`, {
                            published: status
                        })
                        .then(r => {
                            this.$emit('update', r.data.model);
                            this.loading = false;
                        })
                        .catch(e => {
                            this.loading = false;
                        })
            }
        },


    }
</script>

<style lang="scss" scoped>

</style>

<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <ul class="mb-4 award-title d-flex justify-content-between list-unstyled flex-wrap">
                    <li class="mr-2">
                        <span class="badge badge-default">
                            <i class="fa fa-award" />
                            {{ entry.entry_form.category.year.program.name }}
                        </span>
                    </li>

                    <li>
                        <span class="badge badge-default">
                            <i class="fa fa-calendar-alt" />
                            {{ entry.entry_form.category.year.year }}
                        </span>
                    </li>
                    <li>
                        <span class="badge badge-default">
                            <i class="fa fa-certificate" />
                            {{ entry.entry_form.category.name }}
                        </span>
                    </li>
                </ul>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <nav class="mb-4 nav nav-pills nav-fill">
                    <a
                        class="nav-item nav-link"
                        :class="{ active: showEntry }"
                        href="#"
                        @click.prevent="setTab('entry')"
                    >
                        View Entry
                    </a>
                    <a
                        class="nav-item nav-link"
                        :class="{ active: showForm }"
                        href="#"
                        @click.prevent="setTab('form')"
                    >
                        View Judging Form
                    </a>
                </nav>
            </div>
        </div>
        <div
            v-show="showEntry"
            class="row"
        >
            <div class="col-md-12">
                <standard-box>
                    <template #title>
                        <h6 class="m-0 font-weight-bold text-default">
                            <i class="fa fa-info-circle mr-2" />Entry #{{ entry.id }}
                        </h6>
                    </template>
                    <template #body>
                        <entry-list-preview :entry="entry" />
                    </template>
                </standard-box>
            </div>
        </div>
        <div v-show="showForm">
            <show-judging-form
                :category="entry.entry_form.category"
                :judging-form="entry.entry_form.category.judging_form"
                :entry="entry"
            />
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            entry: {
                type: Object,
            },
        },
        data() {
            return {
                currentView: 'entry'
            }
        },
        computed: {
            showEntry() {
                return this.currentView === 'entry'
            },
            showForm() {
                return this.currentView === 'form'
            }
        },
        methods: {
            setTab(value) {
                this.currentView = value;

            }
        },

    }
</script>

<style lang="scss" scoped>
.award-title {
    font-size: 2em;
}


.nav-pills a {
    font-size: 2em;
}
</style>

<template>
    <div>
        <div
            v-if="category && category.judging_criteria"
            class="row mb-4"
        >
            <div class="col-md-12">
                <h2 class="text-danger">
                    Judging Criteria
                </h2>
                <div v-html="category.judging_criteria.description" />
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <form
                    v-if="data && data.length > 0"
                    method="POST"
                    @submit.prevent="submitForm"
                >
                    <div
                        v-for="field in data"
                        :key="field.judging_form_field_id"
                        class="questions"
                    >
                        <div class="row">
                            <div class="col-md-6 font-weight-bold">
                                {{ field.question }}
                            </div>
                            <div class="col-md-6">
                                <div class="ratings-scale justify-content-between w-100 d-flex">
                                    <div class="d-flex flex-column">
                                        <label
                                            class="form-check-label"
                                            for="inlineRadio1"
                                        >
                                            1
                                        </label>
                                        <input
                                            id="inlineRadio1"
                                            v-model="field.value"
                                            type="radio"
                                            :name="'ratings'+field.judging_form_field_id"
                                            value="1"
                                            required
                                        >
                                    </div>
                                    <div class="d-flex flex-column">
                                        <label
                                            class="form-check-label"
                                            for="inlineRadio2"
                                        >
                                            2
                                        </label>
                                        <input
                                            id="inlineRadio2"
                                            v-model="field.value"
                                            type="radio"
                                            :name="'ratings'+field.judging_form_field_id"
                                            value="2"
                                            required
                                        >
                                    </div>
                                    <div class="d-flex flex-column">
                                        <label
                                            class="form-check-label"
                                            for="inlineRadio3"
                                        >
                                            3
                                        </label>
                                        <input
                                            id="inlineRadio3"
                                            v-model="field.value"
                                            type="radio"
                                            :name="'ratings'+field.judging_form_field_id"
                                            value="3"
                                            required
                                        >
                                    </div>
                                    <div class="d-flex flex-column">
                                        <label
                                            class="form-check-label"
                                            for="inlineRadio4"
                                        >
                                            4
                                        </label>
                                        <input
                                            id="inlineRadio4"
                                            v-model="field.value"
                                            type="radio"
                                            :name="'ratings'+field.judging_form_field_id"
                                            value="4"
                                            required
                                        >
                                    </div>
                                    <div class="d-flex flex-column">
                                        <label
                                            class="form-check-label"
                                            for="inlineRadio4"
                                        >
                                            5
                                        </label>
                                        <input
                                            id="inlineRadio5"
                                            v-model="field.value"
                                            type="radio"
                                            :name="'ratings'+field.judging_form_field_id"
                                            value="5"
                                            required
                                        >
                                    </div>
                                    <div class="d-flex flex-column">
                                        <label
                                            class="form-check-label"
                                            for="inlineRadio6"
                                        >
                                            6
                                        </label>
                                        <input
                                            id="inlineRadio6"
                                            v-model="field.value"
                                            type="radio"
                                            :name="'ratings'+field.judging_form_field_id"
                                            value="6"
                                            required
                                        >
                                    </div>

                                    <div class="d-flex flex-column">
                                        <label
                                            class="form-check-label"
                                            for="inlineRadio7"
                                        >
                                            7
                                        </label>
                                        <input
                                            id="inlineRadio7"
                                            v-model="field.value"
                                            type="radio"
                                            :name="'ratings'+field.judging_form_field_id"
                                            value="7"
                                            required
                                        >
                                    </div>

                                    <div class="d-flex flex-column">
                                        <label
                                            class="form-check-label"
                                            for="inlineRadio8"
                                        >
                                            8
                                        </label>
                                        <input
                                            id="inlineRadio8"
                                            v-model="field.value"
                                            type="radio"
                                            :name="'ratings'+field.judging_form_field_id"
                                            value="8"
                                            required
                                        >
                                    </div>

                                    <div class="d-flex flex-column">
                                        <label
                                            class="form-check-label"
                                            for="inlineRadio9"
                                        >
                                            9
                                        </label>
                                        <input
                                            id="inlineRadio9"
                                            v-model="field.value"
                                            type="radio"
                                            :name="'ratings'+field.judging_form_field_id"
                                            value="9"
                                            required
                                        >
                                    </div>

                                    <div class="d-flex flex-column">
                                        <label
                                            class="form-check-label"
                                            for="inlineRadio10"
                                        >
                                            10
                                        </label>
                                        <input
                                            id="inlineRadio10"
                                            v-model="field.value"
                                            type="radio"
                                            :name="'ratings'+field.judging_form_field_id"
                                            value="10"
                                            required
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <label for="">
                                    Comments:
                                </label>
                                <textarea
                                    id="comments"
                                    v-model="field.comments"
                                    name="comments"
                                    class="form-control"
                                    rows="5"
                                />
                            </div>
                        </div>
                    </div>

                    <div
                        v-if="!preview"
                        class="row mt-3"
                    >
                        <div class="col-sm-12">
                            <div class="d-flex align-items-center justify-content-center">
                                <button
                                    class="mr-2 btn btn-default"
                                    @click.prevent="goBack"
                                >
                                    Cancel
                                </button>
                                <button
                                    class="btn btn-success"
                                    type="submit"
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
                <div
                    v-else
                    class="alert alert-warning text-center"
                >
                    <i class="fa fa-exclamation-triangle mr-2" />A Judging Form has not been created for this Entry yet.
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            category: {
                type: Object
            },
            judgingForm: {
                type: Object
            },
            entry: {
                type: Object
            },
            preview: {
                type: Boolean,
                default: false
            },
        },
        data() {
            return {
                data: []
            }
        },
        computed: {
        },

        mounted () {
            this.init();
        },
        methods: {
            goBack() {
                window.location.href = '/judge/entries'
            },
            init() {
                if (this.judgingForm) {
                    this.judgingForm.fields.map(field => {
                        // If existing form is passed in

                        // Else
                        this.data.push({
                            judging_form_field_id: field.id,
                            question: field.value,
                            value: '',
                            comments: ''
                        });

                    });

                }

            },
            submitForm() {
                this.$swal({
                    type: 'warning',
                    text: 'Are you sure?',
                    showConfirmButton: true,
                    showCancelButton: true,
                    reverseButtons: true
                }).then(r => {
                    if (r.value) {
                        axios
                            .post(`/judge/entries/${this.entry.id}/judging-form`, this.data)
                            .then(r => {
                                window.location = r.data.redirect
                            })
                            .catch(e => {
                                console.error(e);

                            })
                    }
                })

            }
        },

    }
</script>

<style lang="scss" scoped>


</style>

<template>
    <div
        class="details-box card shadow pr-3 pl-3 pt-4 pb-4 text-center"
        :class="{ 'bg-white': category.judging_form, 'bg-gray-300': !category.judging_form }"
    >
        <div class="form-action mb-1">
            <template v-if="category.judging_form">
                <a :href="`/admin/judging-forms/${category.judging_form.id}/edit`">
                    <i class="fa fa-edit fs-2" />
                </a>
            </template>
            <template v-else>
                <a :href="`/admin/categories/${category.id}/judging-forms/create`">
                    <i class="fa fa-plus" />
                </a>
            </template>
        </div>
        <div class="title">
            Judging Form
        </div>
        <div
            v-if="category.judging_form"
            class="entry-details"
        />
        <a
            v-if="category.judging_form"
            target="_blank"
            :href="`/admin/judging-forms/${category.judging_form.id}/preview`"
        >
            Preview Form
        </a>
    </div>
</template>

<script>
    export default {
        props: {
            category: {
                type: Object,
            },
        },


    }
</script>

<style lang="scss" scoped>
.entry-details {
    font-size: 1em;
}
.details-box {
    .fa-plus {
        font-size: 3em;
    }
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    min-height: 150px;
}
</style>

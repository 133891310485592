<template>
    <div>
        <form
            action="#"
            role="form"
            @submit.prevent="onSubmit"
        >
            <div class="form-group">
                <label>Name</label>
                <input
                    v-model="data.name"
                    name="name"
                    class="form-control"
                    type="text"
                >
            </div>

            <image-upload-field
                v-model="data.logo"
                field-i-d="logo"
            >
                Logo
            </image-upload-field>

            <image-upload-field
                v-model="data.featured_image"
                field-i-d="featuredImage"
            >
                <i
                    v-tooltip="'This is the image that will be displayed on the individual Program page next to the description.'"
                    class="text-default fa fa-question-circle"
                />
                Featured Image:
            </image-upload-field>

            <image-upload-field
                v-model="data.featured_thumbnail"
                field-i-d="featuredThumbnail"
            >
                <i
                    v-tooltip="'This is the image that will be displayed on the homepage.'"
                    class="text-default fa fa-question-circle"
                />
                Featured Thumbnail:
            </image-upload-field>

            <div class="form-group">
                <label for="">
                    URL
                </label>
                <div
                    class="input-group"
                >
                    <div class="input-group-prepend">
                        <label
                            class="input-group-text"
                            for="programURL"
                        >
                            https://awards.lancasterbuilders.org/
                        </label>
                    </div>

                    <input
                        id="programURL"
                        v-model="data.slug"
                        name="slug"
                        class="form-control"
                        type="text"
                    >
                </div>
            </div>
            <div class="form-group">
                <label for="description">
                    Description
                </label>
                <WYSIWYG-field
                    v-model="data.description"
                    class="border"
                />
            </div>

            <div class="actions d-flex align-items-center justify-content-center">
                <button
                    class="btn btn-default mr-5"
                    @click.prevent="goBack"
                >
                    Cancel
                </button>
                <button class="btn btn-primary">
                    Submit
                </button>
            </div>
        </form>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                data: {
                    name: '',
                    logo: [],
                    featured_image: [],
                    featured_thumbnail: [],
                    slug: '',
                    description: ''
                }
            }
        },
        methods: {
            goBack() {
                window.location = '/admin/programs';
            },
            onSubmit() {
                let formData = window.jsonToFormData(this.data);
                axios
                    .post('/api/programs', formData)
                    .then(r => {
                        if (r.data.success) {
                            if (r.data.redirect) {
                                window.location = r.data.redirect
                            }
                        }
                    })
                    .catch(e => {
                        console.error(e);
                    })

            }
        },

    }
</script>

<style lang="scss" scoped>

</style>

<template>
    <div class="d-flex align-items-center mt-4">
        <label>
            <i
                v-tooltip="'This field will be hidden from Judges during the judging round'"
                class="text-default fa fa-question-circle"
            />
            Judging Visibility:
        </label>

        <p-check
            class="ml-auto p-default p-curve"
            color="success"
            off-color="danger"
            toggle
            :checked="status"
            @click.native="onInput"
        >
            Enabled
            <label slot="off-label">
                Disabled
            </label>
        </p-check>
    </div>
</template>

<script>
    export default {
        model: {
            prop: 'status',
            event: 'input'
        },
        props: ['status'],
        methods: {
            onInput(val) {
                this.$emit('input', !this.status)
                console.log(val);


            }
        },

    }
</script>

<style lang="scss" scoped>

</style>

<template>
    <div>
        <form method="POST">
            <draggable
                v-model="schema.fields"
                :sort="true"
                handle=".card-header"
            >
                <div
                    v-for="(field, index) in schema.fields"
                    :key="index"
                >
                    <div
                        class="row"
                    >
                        <div class="col-md-12">
                            <text-form-field
                                class="draggable-field entry-form-field mb-3"
                                :index="index"
                                :value="field"
                                @input="updateForm"
                                @remove="removeField"
                            />
                        </div>
                    </div>
                </div>
            </draggable>

            <div class="new-field-row">
                <div class="d-flex align-items-center justify-content-center">
                    <button
                        class="btn btn-primary"
                        @click.prevent="addNewField()"
                    >
                        Add New Question
                        <i class="fa fa-plus" />
                    </button>
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-sm-12">
                    <div class="d-flex align-items-center justify-content-center">
                        <button
                            class="mr-2 btn btn-default"
                            @click.prevent="cancel"
                        >
                            Cancel
                        </button>
                        <button
                            class="btn btn-success"
                            @click.prevent="submitForm"
                        >
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
    import { FormBuilderMixin } from '../../mixins/FormBuilderMixin.js'
    export default {
        mixins: [ FormBuilderMixin ],
        props: {
            category: {
                type: Object,
            },
            edit: {
                type: Boolean
            }
        },
        data() {
            return {
                APIurl: '/api/judging-forms',
                name: '',
                schema: {
                    fields: []
                }
            }
        },
        mounted () {
            if (this.edit) {
                this.convertSavedFormDataForTemplate();
            }
        },
        methods: {
            cancel() {
                window.location = `/admin/categories/${this.category.id}`
            },

            /**
             * Massage the data from the server for an existing Form
             * to fit the schema required for the template builder
             */
            convertSavedFormDataForTemplate(data) {
                if (this.category.judging_form){
                    this.schema.fields = this.category.judging_form.fields
                }
            },

            submitEdit(data) {
                data.judging_form_id = this.category.judging_form.id;
                axios
                    .patch(`/api/judging-forms/${this.category.judging_form.id}`, data)
                    .then(r => {
                        if (r.data.success) {
                            if (r.data.redirect) {
                                window.location = r.data.redirect
                            }
                        }
                    })
                    .catch(e => {
                        console.error(e);
                    })
            },

            submitForm() {
                this.saveOrderAttributes();

                let data = {
                    category_id: this.category.id,
                    schema: this.schema
                }
                if (this.edit) {
                    this.submitEdit(data)
                } else {
                    this.submitCreate(data);
                }
            },
            addNewField() {
                let total = this.schema.fields.length
                this.schema.fields.push({
                    value: '',
                    order: total++
                })
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>

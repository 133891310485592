<template>
    <div class="form-group">
        <label :for="fieldID">
            <slot />
        </label>
        <div class="input-group mb-3">
            <div class="custom-file">
                <input
                    :id="fieldID"
                    class="w-100"
                    type="file"
                    @change="addLogo"
                >
                <label
                    class="custom-file-label"
                    :for="fieldID"
                >
                    {{ filename }}
                </label>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        model: {
            prop: 'file',
            event: 'input'
        },
        props: ['file', 'label', 'fieldID'],
        computed: {
            filename() {
                if (this.file.length === 0) {
                    return ' Select File'
                } else {
                    return this.file.name;
                }
            }
        },
        methods: {
          addLogo(event) {
                      let file = event.target.files[0]
                      this.$emit('input', file);
            },
        },
    }

</script>

<style lang="scss" scoped>

</style>

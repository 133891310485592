<template>
    <form
        id="paymentForm"
        method="POST"
        action="/api/payment"
        @submit.prevent="sendPaymentDataToAnet"
    >
        <div class="form-group">
            <label for="fullName">
                Full Name
            </label>
            <input
                data-private
                v-model="cardData.fullName"
                type="text"
                name="fullName"
                required
                class="form-control"
            >
        </div>

        <div class="form-group">
            <label for="cardNumber">
                Card Number
            </label>
            <div class="input-group">
                <input
                    data-private
                    id="cardNumber"
                    v-model="cardData.cardNumber"
                    type="text"
                    name="cardNumber"
                    placeholder="Your card number"
                    class="form-control"
                    required
                >
                <div class="input-group-append">
                    <span class="input-group-text text-muted">
                        <i class="fab fa-cc-visa mx-1" />
                        <i class="fab fa-cc-amex mx-1" />
                        <i class="fab fa-cc-mastercard mx-1" />
                    </span>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-5">
                <div class="form-group">
                    <label>
                        <span class="hidden-xs">
                            Expiration
                        </span>
                    </label>
                    <div class="input-group">
                        <input
                            data-private
                            id="month"
                            v-model="cardData.month"
                            type="number"
                            placeholder="MM"
                            name="month"
                            class="form-control"
                            required
                        >
                        <input
                            data-private
                            id="year"
                            v-model="cardData.year"
                            type="number"
                            placeholder="YY"
                            name="year"
                            class="form-control"
                            required
                        >
                    </div>
                </div>
            </div>
            <div class="col-sm-3">
                <div class="form-group mb-4">
                    <label
                        data-toggle="tooltip"
                        title="Three-digits code on the back of your card"
                    >
                        CVV
                    </label>
                    <input
                        data-private
                        id="cardCode"
                        v-model="cardData.cardCode"
                        type="text"
                        name="cardCode"
                        required
                        class="form-control"
                    >
                </div>
            </div>
            <div class="col-sm-4">
                <div class="form-group">
                    <label for="zip">
                        Zip Code
                    </label>
                    <input
                        data-private
                        id="zip"
                        v-model="cardData.zip"
                        type="number"
                        name="zip"
                        required
                        class="form-control"
                    >
                </div>
            </div>
        </div>

        <div
            v-if="errors.length > 0"
            class="form-group error"
        >
            <div class="errors help-block">
                <span
                    v-for="error in errors"
                    :key="error.code"
                >
                    {{ error.text }}
                </span>
            </div>
        </div>
    </form>
</template>

<script>

    export default {
        props: {
            opaqueData: {
                type: Object,
            },
        },
        data() {
            return {
                errors: [],
                // payment_type: 'now',
                authData: {
                    clientKey: "9979Kp8rd28qp2Cpt7SMRGfUGBzVsQaDn7qgn5m9k3r27pMQCY8JF5Z3Y3wgkKmW",
                    apiLoginID: "lancaster01"
                },
                cardData: {
                    fullName: '',
                    cardNumber: '',
                    month: '',
                    year: '',
                    cardCode: '',
                    zip: ''
                },
            }
        },
        computed: {
            secureData() {
                return {
                    authData: this.authData,
                    cardData: this.cardData
                }
            }
        },
        mounted() {
            Bus.$on('submitPayment', () => this.sendPaymentDataToAnet());
        },
        methods: {
            paymentFormUpdate(opaqueData) {
                this.$emit('input', opaqueData);
                this.$emit('tokenSuccess');
            },
             sendPaymentDataToAnet() {
                this.errors = [];
                Accept.dispatchData(this.secureData, response => {
                    if (response.messages.resultCode === "Error") {
                        this.$emit('tokenFailure');
                        response.messages.message.map(error => {
                            this.errors.push(error);
                        })
                    } else {
                        this.paymentFormUpdate(response.opaqueData);
                    }
                });
            }
        }
    }
</script>




<style lang="scss" scoped>
input.noLastPassStyle {
    background-image:none !important;
    background-attachment:none !important;
    padding-right:0 !important;
    border:1px solid #ABADB3 !important;
}
</style>

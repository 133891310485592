<template>
    <div>
        <form method="POST">
            <draggable
                v-model="schema.fields"
                :sort="true"
                handle=".card-header"
            >
                <template v-for="(field, index) in schema.fields">
                    <div
                        :key="index"
                        class="row"
                    >
                        <div class="col-md-12">
                            <component
                                :is="field.type"
                                :index="index"
                                :value="field"
                                class="draggable-field entry-form-field mb-3"
                                @input="updateForm"
                                @remove="removeField"
                            />
                        </div>
                    </div>
                </template>
            </draggable>

            <div class="new-field-row">
                <div class="d-flex">
                    <select
                        id="inlineFormCustomSelect"
                        v-model="selected"
                        class="custom-select mr-2"
                    >
                        <option value="text-form-field">
                            Text
                        </option>
                        <option value="radios-form-field">
                            Radios
                        </option>
                        <option value="checklist-form-field">
                            Checkboxes
                        </option>
                        <option value="textarea-form-field">
                            Textarea
                        </option>
                        <option value="upload-form-field">
                            File Upload
                        </option>
                    </select>
                    <button
                        class="btn btn-primary"
                        @click.prevent="addNewField()"
                    >
                        <i class="fa fa-plus" />
                    </button>
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-sm-12">
                    <div class="d-flex align-items-center justify-content-center">
                        <button
                            class="mr-2 btn btn-default"
                            @click.prevent="cancel"
                        >
                            Cancel
                        </button>
                        <button
                            class="btn btn-success"
                            @click.prevent="submitForm"
                        >
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
    import { FormBuilderMixin } from '../../mixins/FormBuilderMixin.js'
    export default {
        mixins: [ FormBuilderMixin ],
        props: {
            category: {
                type: Object,
            },
            edit: {
                type: Boolean
            }
        },
        data() {
            return {
                APIurl: '/api/entry-forms',
                fieldTypesIndex: {
                    'text-form-field': 'input',
                    'checklist-form-field': 'checklist',
                    'radios-form-field': 'radios',
                    'textarea-form-field': 'textArea',
                    'upload-form-field': 'upload'
                },
                model: {},
                selected: 'text-form-field',
                name: '',
                schema: {
                    fields: []
                }
            }
        },
        mounted () {
            if (this.edit) {
                if (this.category.entry_form) {
                    let schema = this.convertSavedFormDataForTemplate(this.category.entry_form.fields);
                    this.schema.fields = schema;

                }

            }
        },
        methods: {
            cancel() {
                window.location = `/admin/categories/${this.category.id}`
            },
            /**
             * Massage the data from the server for an existing Form
             * to fit the schema required for the template builder
             */
            convertSavedFormDataForTemplate(data) {
                return data.map((field) => {
                    /**
                     * Figure out what the field type is.
                     * The server schema is slightly different.
                     * If the type is text,
                     * this template also needs the inputType
                     */
                    let type = field.type;
                    if (type === 'input') {
                        type = field.inputType;
                    }
                    type = type.toLowerCase()+'-form-field';

                    let tags = [];
                    if (field.values && field.values.length > 0) {
                        tags = field.values.map(tag => {
                            return {
                                text: tag
                            }
                        });
                    }
                    return {
                        required: field.required,
                        tags: tags,
                        type: type,
                        value: field.label
                    }
                })
            },


            /**
             * Massage the data from the template for server submission
             */
            convertTemplateDataForServer() {

                let form = JSON.parse(JSON.stringify(this.schema));
                form.fields.map(field => {
                    field.type = this.fieldTypesIndex[field.type];
                    if (field.type === 'input') {
                        field.inputType = 'text';
                    }
                    if (field.tags && field.tags.length > 0) {
                        field.values = field.tags.map(tag => tag.text);
                    } else {
                        field.values = null;
                    }
                    field.label = field.value;

                    delete field.value;
                    delete field.tags;
                })
                let data = {
                    category_id: this.category.id,
                    schema: form
                }
                return data;
            },


            submitEdit(data) {

                data.entry_form_id = this.category.entry_form.id;
                axios
                    .patch(`/api/entry-forms/${this.category.entry_form.id}`, data)
                    .then(r => {
                        if (r.data.success) {
                            if (r.data.redirect) {
                                window.location = r.data.redirect
                            }
                        }
                    })
                    .catch(e => {
                        console.error(e);
                    })
            },

            submitForm() {
                this.saveOrderAttributes();

                let data = this.convertTemplateDataForServer();
                if (this.edit) {
                    this.submitEdit(data)
                } else {
                    this.submitCreate(data);
                }


            },

            addNewField() {
                this.schema.fields.push({
                    required: true,
                    type: this.selected,
                    value: '',
                    tags: []
                })
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>

<template>
    <div class="card shadow">
        <a
            :href="'#'+randomId"
            class="d-block card-header py-3"
            data-toggle="collapse"
            role="button"
            aria-expanded="true"
            :aria-controls="randomId"
        >
            <slot name="title" />
        </a>
        <div
            :id="randomId"
            class="collapse show"
            style=""
        >
            <div class="card-body p-0">
                <slot name="body" />
            </div>
            <slot name="footer"/>
        </div>
    </div>
</template>

<script>
import randomstring from 'randomstring'
    export default {
        props: {
            title: {
                type: String,
            },
        },
        data() {
            return {
                randomId: ''
            }
        },
        mounted () {
            this.randomId = randomstring.generate({
                 charset: 'alphabetic'
            });
        },

    }
</script>

<style lang="scss" scoped>

</style>

<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <h1 class="header-title list-unstyled m-0 font-weight-bold text-primary">
                    <span class="mr-2">
                        <i class="fa fa-award" />
                        {{ category.year.program.name }}
                    </span>

                    <span>
                        <span class="badge badge-default">
                            <i class="fa fa-calendar-alt" />
                            {{ category.year.year }}
                        </span>
                    </span>
                    <span>
                        <span class="badge badge-default">
                            <i class="fa fa-certificate" />
                            {{ category.name }}
                        </span>
                    </span>
                </h1>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 d-flex mb-4">
                <a
                    :href="`/admin/results/${category.id}/export`"
                    class="ml-auto btn btn-secondary ml-auto fs-12 btn-icon-split p-0 btn-sm"
                >
                    <span class="icon text-white-50">
                        <i class="fas fa-download" />
                    </span>
                    <span class="text">
                        Export
                    </span>
                </a>
            </div>
        </div>
        <div
            v-for="judge in judges"
            :key="judge.id"
            class="row"
        >
            <div class="col-md-12">
                <accordion-box
                    class="mb-4"
                >
                    <template #title>
                        <div class="d-flex align-items-center">
                            <h6 class="m-0 font-weight-bold">
                                <i class="fa fa-gavel" />
                                {{ judge.name }}
                            </h6>
                        </div>
                    </template>
                    <template #body>
                        <div class="list-group list-group-flush">
                            <a
                                v-for="judging_entry in judgingEntries(judge)"
                                :key="judging_entry.id"
                                href="#"
                                class="d-flex list-group-item align-items-center"
                                @click.prevent="openModal(judging_entry)"
                            >
                                #{{ judging_entry.entry.id }}
                                {{ judging_entry.entry.company_name }}
                                <span class="badge ml-auto">
                                    {{ judging_entry.total_score }}
                                </span>
                            </a>
                        </div>
                    </template>
                </accordion-box>
            </div>
        </div>
    </div>
</template>

<script>
    import JudgingEntryPreviewModal from '../judging-entry/JudgingEntryPreviewModal.vue';
    export default {
        props: {
            category: {
                type: Object
            },
            judges: {
                type: Array,
            },
        },
        methods: {
            /**
             * Only show the judge's entries for this particular category
             */
            judgingEntries(judge) {
                return judge.judging_entries.filter(entry => {
                    if (entry.entry.entry_form) {
                        return entry.entry.entry_form.category_id === this.category.id
                    }
                })
            },
            openModal(entry) {
                this.$modal.show(JudgingEntryPreviewModal, {
                    entry: entry
                }, {
                    scrollable: true,
                    width: '80%',
                    height: 'auto'
                });

            }
        },

    }
</script>

<style lang="scss" scoped>
</style>
